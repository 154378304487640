import React, { useContext } from "react";
import PropTypes from "prop-types";

import { FormatAsDollars, FormatCarName } from "./../../utils/Helpers/Format";
import { FormattedMessage } from 'react-intl'; //useIntl
import sortIncentivesCatalog from "../../functions/incentives/Sort/sortIncentivesCatalog";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import IncentivesCarousel from "../../client_customizations/components/IncentivesCarousel/IncentivesCarousel";

const EVIncentives = ({ car, incentives, usedEv, zipcode, removePersonalize }) => {
  const userPrefs = useContext(UserPrefsContext);
  // const intl = useIntl();
  if (!incentives) return null;

  incentives = incentives.filter(incentive => usedEv ? incentive.applicable_to_used_vehicles : incentive.applicable_to_new_vehicles)

  const sortedIncentives = sortIncentivesCatalog(
    incentives,
    userPrefs.get('incentiveSortType'),
    userPrefs.get('incentiveSortDirection')
  );

  const filteredIncentives = incentives.filter(incentive => {
    return incentive.grantor_type !== 'Power Supplier' || incentive.grantor === 'APS';
  });


  const getIncentiveSum = () => {
    let sum = 0;
    if (!filteredIncentives) return FormatAsDollars(sum);
 
    filteredIncentives.forEach((incentive) => {
      if (incentive.evaluation) {
          const amountInPurchase = incentive.evaluation.amount_in_purchase;
          const amountInLease = incentive.evaluation.amount_in_lease;
          if (amountInPurchase && amountInPurchase !== 0) {
              sum += amountInPurchase;
          } else {
              sum += amountInLease;
          }
      } else {
          sum += incentive.max_amount;
      }
    });

    return FormatAsDollars(sum);
  };

  return (
    <section className="container-fluid EVIncentives">
      <div className="container">
        <div>
          <div className="row">
            <div className="col-12">
              <p className="mb-3 text-center compare-title">
                <FormattedMessage
                  id="ev.incentives.title"
                  defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                  description="EV Incentives Title"
                  values={{
                    savings: (
                      <strong style={{ color: '#429538', fontWeight: 400 }}>
                        {getIncentiveSum()}
                      </strong>
                    ),
                    lineBreak: <br />,
                    car: FormatCarName(car),
                  }}
                />
              </p>
              {/* <div className="text-center" style={{ marginBottom: '31px' }}>
                {!removePersonalize &&
                  <IncentivePreferences
                    titleText="PERSONALIZE INCENTIVES"
                    btnText={
                      intl.formatMessage
                        ? intl.formatMessage({
                            id: 'personalizeIncentives',
                            defaultMessage: 'Personalize Incentives',
                          })
                        : 'Personalize Incentives'
                    }
                    zipcode={zipcode}
                  />
                }
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <IncentivesCarousel incentives={sortedIncentives} />
    </section>
  );
};

export default EVIncentives;

EVIncentives.propTypes = {
  car: PropTypes.object,
  incentives: PropTypes.array,
  removePersonalize: PropTypes.bool
};
