import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {
  FormatAsDollars,
  FormatInThousands,
} from './../../../utils/Helpers/Format';
import { useIntl } from 'react-intl';

const CostToOwnComparisonChart = ({
  carNames,
  carsCosts,
  displayEvCostBreakdownOption,
  displayEvCostBreakdown,
}) => {
  let chartData = {};

  const intl = useIntl();

  chartData = {
    labels: carNames,
    datasets: [
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.vehicle',
          defaultMessage: 'Vehicle after incentives',
        }),
        backgroundColor: '#00657F',
        data: carsCosts.map((carCost) => {
          return carCost.vehicle.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.taxesFeers',
          defaultMessage: 'Taxes and Fees',
        }),
        backgroundColor: `#D1AF1E`,
        data: carsCosts.map((carCost) => {
          return carCost.taxesFees.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.charger',
          defaultMessage: 'Charger',
        }),
        backgroundColor: `#A0A0A0`,
        data: carsCosts.map((carCost) => {
          return carCost.charger.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.electricity',
          defaultMessage: 'Electricity',
        }),
        backgroundColor: `#81CDE0`,
        data: carsCosts.map((carCost) => {
          return carCost.electricity.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.gasoline',
          defaultMessage: 'Gasoline',
        }),
        backgroundColor: `#4F93A6`,
        data: carsCosts.map((carCost) => {
          return carCost.gasoline.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.maintenance',
          defaultMessage: 'Maintenance',
        }),
        backgroundColor: `#FFF`,
        data: carsCosts.map((carCost) => {
          return carCost.maintenance.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.insurance',
          defaultMessage: 'Insurance',
        }),
        backgroundColor: `#000000`,
        data: carsCosts.map((carCost) => {
          return carCost.insurance.total;
        }),
        abbreviatedLabel: '',
      },
      {
        showLine: false,
        legend: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        datalabels: {
          align: 'right',
          color: '#2A2A2A',
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      yAlign: 'center',
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          value = FormatAsDollars(value);
          return ' ' + label + ': ' + value;
        },
        title: function (tooltipItems, data) {
          let total = tooltipItems.reduce(function (acc, item) {
            return acc + item.xLabel;
          }, 0);
          return tooltipItems[0].yLabel + ': ' + FormatAsDollars(total);
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: function (value) {
              value = FormatInThousands(value);
              return value;
            },
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            font: {
              weight: 700,
              color: '#2A2A2A',
            },
          },
        },
      ],
    },
    legend: {
      position: 'bottom',
      onClick: function (e) {
        e.stopPropagation();
      },
      display: true,
      labels: {
        usePointStyle: true,
        boxWidth: 12,
        filter: function (item) {
          return !!item?.text?.trim();
        },
      },
    },
    layout: {
      padding: {
        right: 75,
        top: 25
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.forEach((dataset) => {
              sum += dataset.data[ctx.dataIndex];
            });
            return FormatAsDollars(sum);
          } else if (value === 0) {
            return '';
          } else {
            return ctx.dataset.abbreviatedLabel;
          }
        },
        color: '#fff',
        font: {
          weight: 500,
        },
        anchor: 'center',
      },
      legend: {
        labels: {
          font: {
            size: 14,
            weight: 400,
            textAlign: 'center',
            color: '#2A2A2A',
          },
        },
      },
    },
  };

  return (
    <HorizontalBar
      data={chartData}
      height={212}
      type="horizontalBar"
      options={options}
    />
  );
};

export default CostToOwnComparisonChart;

CostToOwnComparisonChart.propTypes = {
  carNames: PropTypes.array,
  carCosts: PropTypes.array,
  displayEvCostBreakdownOption: PropTypes.bool,
  displayEvCostBreakdown: PropTypes.bool,
  displayFuelOnly: PropTypes.bool,
};