
import React from 'react'
import { FormatAsDollars } from '../../../utils/Helpers/Format';
import PropTypes from 'prop-types';
import "./IncentiveSaves.scss"
import { FormattedMessage } from 'react-intl';

const IncentiveSaves = ({ incentives }) => {

  const filteredIncentives = incentives.filter(incentive => {
    return incentive.grantor_type !== 'Power Supplier' || incentive.grantor === 'APS';
  });

  const getIncentiveSum = () => {
    let sum = 0;
    if (!filteredIncentives) return FormatAsDollars(sum);

    filteredIncentives.forEach((incentive) => {
      if (incentive.evaluation) {
          const amountInPurchase = incentive.evaluation.amount_in_purchase;
          const amountInLease = incentive.evaluation.amount_in_lease;
          if (amountInPurchase && amountInPurchase !== 0) {
              sum += amountInPurchase;
          } else {
              sum += amountInLease;
          }
      } else {
          sum += incentive.max_amount;
      }
    });

    return FormatAsDollars(sum);
};

  return (
    <div className="incentives-sum-container">
      <FormattedMessage
        id="incentives.qualify"
        defaultMessage="You may qualify for up to {sum} savings from vehicle tax credits and rebates."
        description="You may qualify for up to {sum} savings from vehicle tax credits and rebates."
        values={{
          sum: <strong>{getIncentiveSum()}</strong>,
        }}
      />
    </div>
  );
};

export default IncentiveSaves;

IncentiveSaves.propTypes = {
  incentives: PropTypes.array,
};
