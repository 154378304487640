import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
} from 'reactstrap';

import logo from '../../../assets/images/SJCELogo.png';
import menu from '../../../assets/images/menu.svg';
import close from '../../../assets/images/close-icon.svg';
import './Header.scss';
import { FormattedMessage } from 'react-intl';

const Header = ({
  page,
  zipcode,
  activePage,
  userLocation,
  language,
  changeLanguage,
}) => {
  const [collapse, setCollapse] = useState(false);

  const isHomepage =
    window.location.pathname === '/' || window.location.pathname === null;

  const onChangeLanguage = (e) => {
    changeLanguage(e);
  };

  const handleClose = () => {
    setCollapse(false)
  }

  const mobile = window.innerWidth < 720;

  return (
    <div className="Header">
      <div className="container-fluid" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div
            style={{
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
            }}
          >
            <Link to="/" className={isHomepage ? 'active' : ''}>
              <img
                src={logo}
                className="homepagelogo"
                alt="San Jose EV Logo"
                style={{ maxWidth: '190px' }}
              />
            </Link>
          </div>
          {mobile ? <img 
            src={menu} 
            onClick={() => setCollapse(!collapse)}
            style={{ width: '24px', height: 'auto' }} 
            alt='open menu'
          /> : null}
          <Collapse navbar className='d-none d-lg-block'>
            <Nav navbar>
              <NavItem>
                <Link
                  to="/vehicles"
                  className={activePage === 'vehicles' ? 'active' : ''}
                >
                  <span>
                    <FormattedMessage
                      id="electricVehiclesHeader"
                      defaultMessage="Shop Electric"
                      description="Shop Electric Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <NavLink
                  title={page === 'incentives' ? 'Active Page' : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage
                      id="incentives"
                      defaultMessage="INCENTIVES"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <Link
                  to="/charging-stations"
                  className={activePage === 'map' ? 'active' : ''}
                >
                  <span>
                    <FormattedMessage
                      id="chargingStations"
                      defaultMessage="Charging"
                      description="Charging Header"
                    />
                  </span>
                </Link>
              </NavItem>

              <NavItem>
                <button
                  className={`language-button ${language === 'EN' && 'active'}`}
                  onClick={() => onChangeLanguage('EN')}
                >
                  English
                </button>
              </NavItem>

              <NavItem>
                <button
                  className={`language-button ${language === 'ES' && 'active'}`}
                  onClick={() => onChangeLanguage('ES')}
                >
                  Español
                </button>
              </NavItem>

              <NavItem>
                <button
                  className={`language-button ${language === 'VI' && 'active'}`}
                  onClick={() => onChangeLanguage('VI')}
                >
                  tiếng việt
                </button>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div className={`mobile-nav ${collapse ? 'show' : ''} d-block d-lg-none`}>
          {collapse && 
            <div className='mobile-nav-container'>
              <div className="mobile-nav-header">
                <img 
                  src={close} 
                  onClick={() => setCollapse(!collapse)} 
                  alt='close icon'
                />
              </div>
              <div className="mobile-nav-body">
                <Nav navbar>
                  <NavItem onClick={handleClose}>
                    <Link
                      to="/vehicles"
                      className={activePage === 'vehicles' ? 'active' : ''}
                    >
                      <span>
                        <FormattedMessage
                          id="electricVehiclesHeader"
                          defaultMessage="Shop Electric"
                          description="Shop Electric Header"
                        />
                      </span>
                    </Link>
                  </NavItem>
                  <NavItem onClick={handleClose}>
                    <NavLink
                      title={page === 'incentives' ? 'Active Page' : null}
                      to="/incentives"
                    >
                      <span>
                        <FormattedMessage
                          id="incentives"
                          defaultMessage="INCENTIVES"
                          description="Incentives Header"
                        />
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem onClick={handleClose}>
                    <Link
                      to="/charging-stations"
                      className={activePage === 'map' ? 'active' : ''}
                    >
                      <span>
                        <FormattedMessage
                          id="chargingStations"
                          defaultMessage="Charging"
                          description="Charging Header"
                        />
                      </span>
                    </Link>
                  </NavItem>

                  <NavItem>
                    <button
                      className={`language-button ${language === 'EN' && 'active'}`}
                      onClick={() => onChangeLanguage('EN')}
                    >
                      English
                    </button>
                  </NavItem>

                  <NavItem>
                    <button
                      className={`language-button ${language === 'ES' && 'active'}`}
                      onClick={() => onChangeLanguage('ES')}
                    >
                      Español
                    </button>
                  </NavItem>

                  <NavItem>
                    <button
                      className={`language-button ${language === 'VI' && 'active'}`}
                      onClick={() => onChangeLanguage('VI')}
                    >
                      tiếng việt
                    </button>
                  </NavItem>
                </Nav>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
