import React from 'react';
import './Footer.scss';
import FacebookIcon from '../../../assets/images/icons/Facebook.svg';
import InstagramIcon from '../../../assets/images/icons/Instagram.svg';
import XIcon from '../../../assets/images/icons/X.svg';

import logo from '../../../assets/images/SJCELogo.png';
import logoWhite from '../../../../assets/images/zappyLogoWhite.svg';
import { useIntl } from 'react-intl';


const Footer = () => {
  const year = new Date().getFullYear();
  const intl = useIntl();
  

  return (
  <footer className='wrapper'>
    <div className="Footer">
      <div className='icon-wrapper'>
        <img
          src={logo}
          className="logo"
          alt="San Jose EV Logo"
          style={{ maxWidth: '190px' }}
        />
      </div>
      <div className="address-column">
        <span> {intl.formatMessage({id: "footer.links.title"})} </span>
        <ul>
          <li>200 E. Santa Clara St.</li>
          <li>San José, CA 95113</li>
          <li>833-432-2454</li>
          <li>info@sanjosecleanenergy.org</li>
        </ul>
      </div>
      <div className="address-column list">
        <ul>
          <li> {intl.formatMessage({id: "footer.links.contact"})} </li>
          <li> {intl.formatMessage({id: "footer.links.join"})} </li>
          <li> {intl.formatMessage({id: "footer.links.media"})} </li>
          <li> {intl.formatMessage({id: "footer.links.opt"})}</li>
          <li> {intl.formatMessage({id: "footer.links.customer"})}</li>
          <li> {intl.formatMessage({id: "footer.links.policy"})}</li>
          <li> {intl.formatMessage({id: "footer.links.sanJose"})}</li>
        </ul>
      </div>
      <div className="others-column">
        
        <div className="social-media-row">
          <a
            href="https://www.facebook.com/KenGarffAuto/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-media-icon"
              alt="Facebook Icon"
              src={FacebookIcon}
            />
          </a>
          <a
            href="https://twitter.com/kengarff"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-media-icon" alt="Facebook Icon" src={XIcon} />
          </a>
          <a
            href="https://www.instagram.com/kengarff/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-media-icon"
              alt="Linkedin Icon"
              src={InstagramIcon}
            />
          </a>
        </div>
      </div>
    </div>
    <div className='legal-container'>
      <p>San Jose CLean Energy | ©{year} City of San José. {intl.formatMessage({id: "footer.bottom.rights"})} </p>

      <div className='zappy-container' >
        <p>{intl.formatMessage({id: "footer.bottom.provide1"})}</p>
        <img src={logoWhite} alt='san jose logo' />
        <p>, {intl.formatMessage({id: "footer.bottom.provide2"})}</p>
      </div>
      
      <p> {intl.formatMessage({id: "footer.bottom.text"})}</p>
    </div>
  </footer>
  );
};

export default Footer;
