import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import getFilteredData from '../../../../utils/Helpers/getFilteredData';
import { FormattedMessage } from 'react-intl';
import sortIncentivesCatalog from '../../../../functions/incentives/Sort/sortIncentivesCatalog';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import IncentivesCarousel from '../../IncentivesCarousel/IncentivesCarousel'

const HomepageIncentives = ({ incentives, language }) => {
  const [ width, setWidth ] = useState(null)

  useEffect(() => {
    if(language === 'EN') setWidth(1008)
    if(language === 'ES') setWidth(1008)
    if(language === 'VI') setWidth(1145)
  },[language])

  const userPrefs = useContext(UserPrefsContext);
  incentives = getFilteredData(
    [
      { field: 'grantor', value: 'Federal', count: 1 },
      { field: 'grantor_type', value: 'State', count: 2 },
      { field: 'grantor_type', value: 'Power Supplier', count: 3 },
      { field: 'grantor_type', value: 'Power Supplier', count: 4 },
    ],
    incentives
  );

  const sortedIncentives = sortIncentivesCatalog(
    incentives,
    userPrefs.get('incentiveSortType'),
    userPrefs.get('incentiveSortDirection')
  );

  return (
    <section className="homepage-incentives">
      <div className="homepage-incentives-title-container">
        <h2 className="homepage-incentives-title">
          <FormattedMessage
            id="homepage.incentives.title"
            defaultMessage="Maximize potential ev incentives and Tax credits."
            description="Homepage Incentives Title"
          />
        </h2>
        <div className='homepage-incentives-subtitle-container'>
          <p className="homepage-incentives-title-sub" style={{maxWidth: width}}>
            <FormattedMessage
              
              id="homepage.incentives.subTitle"
              defaultMessage="See how much you could save getting behind the wheel of an EV,
                  whether you are buying or leasing. {lineBreak} Incentives are personalized for
                  where you live."
              description="Homepage Incentives Sub Title"
              values={{
                lineBreak: <br />,
              }}
            />
          </p>
        </div>
        <div className='homepage-home-charger-get' style={{ width: '100%'}}>
        <Link to="/incentives">
          <button className="btn-incentives-homepage">
            <FormattedMessage
              id="personalizeIncentives"
              defaultMessage="Personalize Incentives"
              description="Personalize Incentives"
            />
          </button>
        </Link>
      </div>
      </div>
      <IncentivesCarousel incentives={sortedIncentives} />
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array,
};
