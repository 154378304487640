export default {
  electricVehicles: 'Xe điện',
  usedElectricVehicles: 'Xe điện cũ',
  electricVehiclesHeader: 'MUA SẮM TỪ KHO HÀNG',
  compareVehicles: 'SO SÁNH CÁC LOẠI XE',
  incentives: 'ƯU ĐÃI',
  chargingStations: 'TRẠM SẠC',
  homeChargers: 'SẠC TẠI NHÀ',
  dealers: 'Đại lý',
  newVehicles: 'Xe mới',
  electricians: 'Thợ điện',
  homeChargingAdvisor: 'Cố vấn sạc tại nhà',

  'evsCatalog.title': 'Xe điện',
  'evsCatalog.subtitle':
    'Xe điện đang trở nên ngày càng phổ biến. Tìm chiếc xe dành riêng cho bạn ngay hôm nay.',

  electricity: 'Điện',
  gasoline: 'Xăng',
  total: 'Tổng',
  description: 'Mô tả',
  detailedCalculations: 'Chi tiết',
  charging: 'Sạc',
  emissions: 'Khí thải',
  source: 'Nguồn',
  vehicles: 'Xe',
  make: 'Hãng',
  model: 'Người mẫu',
  trim: 'Cắt tỉa',
  all: 'Tất cả',
  age: 'Tuổi',
  zipcode: 'MÃ BƯU CHÍNH',

  'incentiveSort.largest': 'Lớn nhất đầu tiên',
  'incentiveSort.aToZ': 'Theo thứ tự chữ cái: A đến Z',
  'incentiveSort.zToA': 'Theo thứ tự chữ cái: Z đến A',
  'incentives.selectMake': 'Chọn Thực hiện',
  'incentives.selectModel': 'Chọn mẫu',
  'incentives.selectTrim': 'Chọn Cắt',

  personalizeIncentives: 'Ưu đãi được cá nhân hóa',

  'FAQ.title': 'Câu hỏi thường gặp',
  'FAQ.subtitle':
    'Xe điện vẫn còn là một khái niệm mới và việc đặt câu hỏi về xe điện là hoàn toàn tự nhiên. Hãy tìm câu trả lời ở bên dưới.',

  'vehicle.miles': 'dặm',
  'vehicle.trees': 'cây',
  'vehicle.view': 'Xem',
  'vehicle.more': 'thêm',
  'vehicle.fewer': 'ít hơn',
  'vehicle.greater': 'nhiều hơn',

  'vehicle.plugInHybrid': 'Lai cắm điện',
  'vehicle.allElectric': 'Chạy hoàn toàn bằng điện',
  'vehicle.gas': 'Xăng',
  'vehicle.afterIncentives': 'SAU ƯU ĐÃI',
  'vehicle.msrp': 'MSRP',
  'vehicle.estimatedIncentives': 'ƯU ĐÃI ƯỚC TÍNH',
  'vehicle.offMSRP': 'MSRP ƯỚC TÍNH',
  'vehicle.offIncentives': 'ƯU ĐÃI ƯỚC TÍNH',
  'vehicle.downPaymentMonth': 'trong {months} tháng',
  'vehicle.downPayment': '+ tiền trả góp lần đầu {downPayment}',
  'vehicle.plusTax': 'cộng thuế',
  'vehicle.type': 'Loại',
  'vehicle.subtype': 'Loại phụ',
  'vehicle.gvwr': 'GVWR',
  'vehicle.gvwr.value': '{value} lb',
  'vehicle.weightClass': 'Hạng tải trọng',
  'vehicle.drivetype': 'Loại dẫn động',
  'vehicle.transmission': 'Hộp số',
  'vehicle.transmission.automatic': 'Tự động',
  'vehicle.payload': 'Tải trọng',
  'vehicle.seats': 'Chỗ ngồi',
  'vehicle.cargoSpace': 'Khoang hành lý',
  'vehicle.cargoSpace.value': '{cargoSpace} foot khối',
  'vehicle.batteryCapacity': 'Dung lượng pin',
  'vehicle.batteryCapacity.value': '{batteryCapacity} kWh',
  'vehicle.electricEfficiency': 'Hiệu suất tiêu thụ điện',
  'vehicle.electricEfficiency.value': '{electricEfficiency} MPGe',
  'vehicle.gasEfficiency': 'Hiệu suất tiêu thụ xăng',
  'vehicle.gasEfficiency.value': '{gasEfficiency} MPG',
  'vehicle.electricRange': 'Phạm vi chạy điện',
  'vehicle.electricRange.value': '{electricRange} dặm',
  'vehicle.totalRange': 'Tổng phạm vi',
  'vehicle.totalRange.value': '{totalRange} dặm',
  'vehicle.maxACChargeRate': 'Công suất sạc AC tối đa',
  'vehicle.maxACChargeRate.value': '{maxACChargeRate} kW',
  'vehicle.maxDCChargeRate': 'Công suất sạc DC tối đa',
  'vehicle.maxDCChargeRate.value': '{maxDCChargeRate} kW',
  'vehicle.chargeTimeLevel2': 'Thời gian sạc - Cấp 2',
  'vehicle.chargeTimeDC': 'Thời gian sạc - DC',
  'vehicle.plugTypes': 'Kiểu cắm',

  //aca
  'vehicle.fuelType': 'Loại nhiên liệu',
  'vehicle.batterySize': 'KÍCH THƯỚC PIN',
  'vehicle.timeToChargeLvlTwo': 'THỜI GIAN SẠC - CẤP 2',
  'vehicle.milesPerThirtyFastCharge': 'DẶM MỖI 30 PHÚT SẠC NHANH',
  'vehicle.coTwoEmissions': 'GIẢM KHÍ THẢI CO2',
  'vehicle.phev': 'Điện và xăng',
  'vehicle.bev': 'Điện',
  'vehicle.age.one': '0-1 năm tuổi',
  'vehicle.age.two': '2-3 năm tuổi',
  'vehicle.age.four': 'trên 4 năm tuổi',
  'vehicle.power.all': 'Tất cả',
  'vehicle.power.high': 'Cao',
  'vehicle.power.public': 'Công cộng',
  'vehicle.power.other': 'Khác',

  'vehicle.type.sedan': 'xe sedan',
  'vehicle.type.hatchback': 'xe hatchback',
  'vehicle.type.coupe': 'xe coupe',
  'vehicle.type.crossover': 'chéo',
  'vehicle.type.minivan': 'Xe tải nhỏ',
  'vehicle.type.suv': 'SUV',
  'vehicle.type.wagon': 'toa xe',
  'vehicle.type.truck': 'xe cam nhông',

  'header.electricVehicles': 'Xe điện',
  'header.compareVehicles': 'So sánh các loại xe',
  'header.incentives': 'Ưu đãi',
  'header.chargingStations': 'Sạc công cộng',
  'header.homeChargers': 'Bộ sạc tại nhà',
  'header.subHeader':
    'Có thể cung cấp tín dụng thuế liên bang. Số tiền thực tế sẽ khác nhau. Hãy tham khảo cố vấn về thuế của bạn. Trong khi đó, chúc bạn duyệt tìm vui vẻ!',

  'footer.electricVehicles': '{electricVehicles}',
  'footer.compareVehicles': '{compareVehicles}',
  'footer.incentives': '{incentives}',
  'footer.chargingStations': '{chargingStations}',
  'footer.homeChargers': '{homeChargers}',
  'footer.allRights': 'Bảo lưu mọi quyền.',
  'footer.poweredBy': 'Được cung cấp bởi Zappyride',

  'homepage.welcome': 'Colton Recharged',
  'homepage.welcomeSub':
    'Hướng dẫn về xe điện dành cho bạn, do Colton Electric Utility thực hiện. Ước tính và so sánh chi phí, khoản tiết kiệm, ưu đãi xe điện, v.v.',

  'homepage.linkCard.browseElectricVehicles': 'Khám phá các loại xe điện',
  'homepage.linkCard.browseElectricVehicles.short': 'Khám phá các loại xe điện',
  'homepage.linkCard.discoverIncentives': 'Tối ưu hóa các ưu đãi',
  'homepage.linkCard.locateChargingStations': 'Tìm trạm sạc',
  'homepage.linkCard.homeChargers': 'Tìm trạm sạc',

  'homepage.vehicleCarousel.title': 'Xe điện đang trở nên ngày càng phổ biến.',
  'homepage.vehicleCarousel.title.discover':
    'Khám phá chiếc xe dành riêng cho bạn.',
  'homepage.vehicleCarousel.subTitle':
    'So sánh giá của chiếc xe bạn đã chọn với chiếc xe tương tự nhưng chạy bằng xăng. Xe điện có thể giúp bạn tiết kiệm chi phí sở hữu và bảo trì lớn hơn so với ô tô chạy hoàn toàn bằng xăng.',
  'homepage.vehicleCarousel.findEVButton': 'Xem tất cả xe',
  'homepage.vehicleCarousel.compareVeiclesButton': 'SO SÁNH CÁC LOẠI XE',
  'homepage.incentives': 'Khám phá ưu đãi',
  'homepage.compare': 'So sánh các loại xe',
  'homepage.charging': 'Tìm trạm sạc',
  'homepage.browse': 'Mua xe điện',
  'homepage.banner.title': 'Hướng dẫn dành cho bạn về xe điện.',
  'homepage.banner.title.end':
    'Ước tính và so sánh chi phí, khoản tiết kiệm, ưu đãi, v.v.',
  'homepage.banner.button.text': 'TÌM XE ĐIỆN CHO RIÊNG BẠN',

  'homepage.faq.title.questions':
    'CÁC CÂU HỎI VỀ XE ĐIỆN, <highline>ĐÃ TRẢ LỜI.</highline>',
  'homepage.faq.title.answered': 'ĐÃ TRẢ LỜI',
  'homepage.faq.subtitle':
    'Tìm câu trả lời cho những câu hỏi thường gặp về sạc, ưu đãi, các loại xe, v.v. cho xe điện',

  'graph.assumptions': 'GIẢ THIẾT',
  'graph.yourEV': '(Xe bạn chọn)',
  'graph.similarGasVehicle': '(Xe chạy bằng xăng tương tự)',
  'graph.graphValues': 'CÁC GIÁ TRỊ TRONG BIỂU ĐỒ',
  'graph.show': 'Hiển thị',
  'graph.hide': 'Ẩn',
  'graph.moreExpensive': 'tốn kém hơn',
  'graph.cheaper': 'rẻ hơn',
  'graph.toOwnOver': 'để sở hữu trong',
  'graph.years': ' Năm',
  'graph.year': ' Năm',

  'graph.costOfOwnership.title': 'Chi phí sở hữu',
  'graph.costOfOwnership.subTitle':
    '{carName} sẽ {costDeltaText} {costDeltaTextEnding}',
  'graph.costOfOwnership.subTitleThreeCars':
    '{carName} là loại rẻ nhất bạn có thể sở hữu trong {yearsOfOwnership} năm',
  'graph.costOfOwnership.descriptionRow.vehicle': 'Xe',
  'graph.costOfOwnership.descriptionRow.maintenance': 'Bảo trì',
  'graph.costOfOwnership.descriptionRow.insurance': 'Bảo hiểm',
  'graph.costOfOwnership.descriptionRow.electricity': 'Điện',
  'graph.costOfOwnership.descriptionRow.gasoline': 'Xăng',
  'graph.costOfOwnership.totalRow': 'Tổng',
  'graph.costOfOwnership.description': 'Mô tả',

  'graph.fuelCostGraphic.similarGasVehicle': '(Xe chạy bằng xăng tương tự)',
  'graph.fuelCostGraphic.yourSelection': '(Xe bạn chọn)',

  'graph.costOfOwnership.chart.vehicle': 'Xe sau ưu đãi',
  'graph.costOfOwnership.chart.taxesFeers': 'Thuế và phí',
  'graph.costOfOwnership.chart.electricity': 'Điện',
  'graph.costOfOwnership.chart.gasoline': 'Xăng',
  'graph.costOfOwnership.chart.maintenance': 'Bảo trì',
  'graph.costOfOwnership.chart.insurance': 'Bảo hiểm',

  'graph.title.costAnalysis': 'Phân tích chi phí',
  'graph.title.speedOfCharge': 'Tốc độ sạc',
  'graph.title.electricRange': 'Phạm vi chạy điện',
  'graph.monthlyCostToFill.title': 'Chi phí nạp đầy hàng tháng',

  'graph.speedOfCharge.levelTwoChargingSpeed': 'Tốc độ của bộ sạc cấp 2',
  'graph.speedOfCharge.fastChargingSpeed': 'Tốc độ sạc nhanh',
  'graph.speedOfCharge.mphLevelTwoCharger': 'dặm mỗi giờ với bộ sạc cấp 2',
  'graph.speedOfCharge.thirtyFastCharge': 'dặm mỗi 30 phút sạc nhanh',
  'graph.speedOfCharge.subTitle':
    '{car} sạc {number} {costDeltaText} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerThreeCars':
    'sạc nhiều dặm nhất mỗi giờ với bộ sạc cấp 2',
  'graph.speedOfCharge.thirtyFastChargeThreeCars':
    'sạc nhiều dặm nhất trong mỗi 30 phút sạc nhanh',
  'graph.speedOfCharge.subTitleThreeCars': '{car} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerTitle':
    'Điện tích mỗi giờ đối với sạc cấp 2',
  'graph.speedOfCharge.fastChargerTitle':
    'Điện tích mỗi 30 phút đối với sạc nhanh',
  'graph.electricRange.subTitleThreeCars':
    '{car} có phạm vi chạy điện dài nhất',
  'graph.electricRange.subTitle':
    'Phạm vi chạy điện của {car} là {costDeltaText} cho {number} dặm',
  'graph.electricRange.gasolineRange': 'Phạm vi chạy xăng',
  'graph.electricRange.assumption': 'Phạm vi dựa trên ước tính của EPA',
  'graph.monthlyCostToFill.subTitle':
    '{carName} tiêu tốn {style} {costDeltaText} để nạp đầy hàng tháng.',
  'graph.monthlyCostToFill.subTitleThreeCars':
    '{carName} có chi phí nạp đầy hàng tháng thấp nhất.',

  'homepage.incentives.title':
    'Tối đa hóa các ưu đãi xe điện và tín dụng Thuế tiềm năng.',
  'homepage.incentives.subTitle':
    'Xem bạn có thể tiết kiệm được bao nhiêu khi mua hoặc thuê một chiếc ô tô điện. Ưu đãi được cá nhân hóa ở nơi bạn sống.',
  'homepage.incentives.exporeIncentives': 'KHÁM PHÁ ƯU ĐÃI',

  'homepage.chargerBanner.title':
    'Bạn đang tìm Bộ sạc tại nhà? <highline>Chúng tôi ở đây để giúp bạn.</highline>',
  'homepage.chargerBanner.subTitle':
    'Nhận dịch vụ lắp đặt bộ sạc Cấp 2 bởi thợ điện chuyên nghiệp.',

  'homepage.chargerBanner.chargerButton': 'MUA BỘ SẠC',

  'compareVehicles.subTitle': 'Chọn tối thiểu 2 loại xe để bắt đầu so sánh.',
  'compareVehicles.selectFuel': 'Chọn loại nhiên liệu',
  'compareVehicles.selectMake': 'Chọn hãng xe',
  'compareVehicles.selectModel': 'Chọn model xe',
  'compareVehicles.pickTwo': 'Chọn tối thiểu 2 loại xe để bắt đầu so sánh',
  'compareVehicles.disclaimer': 'Không phải hãng / model xe nào cũng có sẵn.',
  'compareVehicles.selectYear': 'Chọn năm',

  'evs.title': 'Khám phá các loại xe điện và kho hàng tại địa phương',
  'evs.welcomeSub': 'Tìm chiếc xe phù hợp với bạn.',
  'evs.buttonMatchScoreAndFilters': 'MỞ BỘ LỌC',
  'evs.matchScoreAndFilters': 'Bộ lọc',
  'evs.matchScoreOptions': 'Bộ lọc',
  'evs.electricFuelFilter': 'Chạy hoàn toàn bằng điện',
  'evs.hybridFuelFilter': 'Lai cắm điện',
  'evs.incentivesVehicleOptions': 'Xe điện được xem xét',

  'evs.roundTripCommute': 'Phạm vi tối thiểu',
  'evs.budgetAfterIncentives': 'Ngân sách sau ưu đãi',
  'evs.minSeats': 'CHỖ NGỒI TỐI THIỂU',
  'evs.availableNow': 'HIỆN CÓ SẴN',
  'evs.availableTooltip':
    'Những chiếc xe hiện có sẵn có thể được mua để giao hàng thay vì chỉ mở cửa cho các đơn đặt hàng trước. Hàng tồn kho thực tế có thể thay đổi tại địa phương.',
  'evs.seats': 'chỗ ngồi',
  'evs.homeChargingAvailability': 'Tình trạng sẵn có của sạc tại nhà',
  'evs.homeChargingAvailabilityTooltip':
    'Lái xe và sạc xe điện thuận tiện nhất khi xe được sạc ở nơi bạn sống',
  'evs.noCharging': 'Không sạc',
  'evs.levelOne': 'Cấp 1',
  'evs.levelTwo': 'Cấp 2',
  'evs.helpMeChoose': 'Chọn giúp tôi',
  'evs.chargingAvailability': 'Tình trạng sẵn có của sạc tại nhà',
  'evs.disclaimer':
    'Các xe được hiển thị có thể không phản ánh tình trạng sẵn có thực tế. {company} không xác nhận hoặc đề xuất bất cứ loại xe hoặc nhà sản xuất ô tô cụ thể nào.',
  'evs.thirdParty':
    'Chúng tôi không xác nhận hoặc đề xuất bất cứ loại xe hoặc nhà sản xuất ô tô cụ thể nào. Các xe được hiển thị có thể không phản ánh tình trạng sẵn có thực tế.',
  'chargingWizard.carOvernight': 'Bạn đỗ xe qua đêm ở đâu?',
  'chargingWizard.parkingGarage': 'Nhà xe tư nhân hoặc bãi đỗ xe',
  'chargingWizard.onTheStreet': 'Trên đường',
  'chargingWizard.publicParking': 'Bãi đỗ xe công cộng',
  'chargingWizard.electricianInstall':
    'Thợ điện có được phép lắp đặt trạm sạc không?',
  'chargingWizard.overnightParking':
    'Bạn có thể đỗ xe qua đêm ở bãi đỗ xe có trang bị trạm sạc không?',
  'chargingWizard.powerOutlet':
    'Có ổ cắm điện thường nào ở gần nơi bạn đỗ xe không?',
  'chargingWizard.chargingAvailability': ' tình trạng sẵn có của trạm sạc',
  'chargingWizard.recommended': 'KHUYẾN NGHỊ',
  'chargingWizard.noChargingAvailability': 'Không có',
  'chargingWizard.youWouldHave': 'bạn nên chọn {resultText} ',
  'chargingWizard.useThisValue': 'Sử dụng giá trị này',

  evfilter: 'Bộ lọc',
  'evfilter.fuel': 'Nhiên liệu',
  'evfilter.fuelTooltip':
    'Xe chạy hoàn toàn bằng điện sẽ chỉ sử dụng điện. Xe lai cắm điện có thể dùng cả điện và xăng.',
  'evfilter.type': 'Loại',

  egbBringCharger: 'Tôi sẽ dùng bộ sạc Cấp 2 của bản thân',

  yes: 'Có',
  no: 'Không',

  'evCard.electricRange': 'Phạm vi chạy điện',
  'evCard.totalRange': 'Tổng phạm vi',
  'evCard.matchScore': 'ĐIỂM TRÙNG KHỚP',
  'evCard.seeDetails': 'CHI TIẾT',
  'evCard.seeElectricVehicle': 'XEM XE ĐIỆN',

  'evSort.title': 'SẮP XẾP THEO',
  'evSort.matchScore': 'Điểm trùng khớp',
  'evSort.electricRange': 'Phạm vi chạy điện',
  'evSort.priceLtH': '$: Thấp đến cao',
  'evSort.priceHtL': '$: Cao đến thấp',
  'evSort.alphabeticaldesc': 'Hãng: A đến Z',
  'evSort.alphabeticalasc': 'Hãng: Z đến A',

  'pricePanels.cash': 'Tiền mặt',
  'pricePanels.loan': 'Trả góp',
  'pricePanels.lease': 'Thuê',
  'pricePanels.loanPayment': 'Tiền trả góp',
  'pricePanels.downPayment': 'Tiền trả góp lần đầu',
  'pricePanels.leasePayment': 'Tiền thuê',
  'pricePanels.perMonth': ' / tháng',
  'pricePanels.downPaymentDescription': '10% MSRP cộng thuế',
  'pricePanels.firstLeasePayment': 'THANH TOÁN TIỀN THUÊ LẦN ĐẦU',
  'pricePanels.incentivesForLease': 'Ưu đãi khi thuê',
  'pricePanels.seeAllIncentives': 'Xem tất cả ưu đãi',
  'pricePanels.seeAllCosts': 'Xem toàn bộ chi phí',
  pri: 'Xem toàn bộ chi phí',

  'ev.jumbotron.viewAllCars': 'Xem tất cả xe',
  'ev.jumbotron.maximizeIncentives': 'XEM ƯU ĐÃI',
  'ev.jumbotron.totalCost': 'XEM TOÀN BỘ CHI PHÍ',
  'ev.jumbotron.compare': 'SO SÁNH VỚI XE KHÁC',
  'ev.jumbotron.localInventory': 'MUA SẮM TỪ KHO HÀNG TẠI ĐỊA PHƯƠNG',

  'ev.carDetails.table.title': 'Thông số xe',
  'ev.carDetails.table.footer':
    '* Đây là giá trị ước tính. Bạn có thể cần liên hệ với nhà sản xuất để biết giá chính xác hoặc được chiết khấu.{br} Thông số xe hiển thị là thông số tiêu chuẩn nhưng các giá trị vẫn có thể thay đổi. Các loại xe được liệt kê chỉ nhằm mục đích tham khảo với tình trạng sẵn có phụ thuộc vào kho hàng tại địa phương.{br} {br} Tính năng tính toán số tiền tiết kiệm chỉ cung cấp con số ước tính minh họa và không đảm bảo độ chính xác của chi phí, khoản tiết kiệm, thông số phần cứng hoặc ưu đãi ước tính. Vui lòng tham khảo từ các nguồn tham khảo và nhà sản xuất thiết bị gốc để cập nhật về chi phí, thông số kỹ thuật phần cứng và thông tin ưu đãi.',

  'ev.carDetails.view.subtitle': 'MSRP - {msrp}',
  'ev.carDetails.fastChargingTooltip': 'Tốc độ của bộ sạc nhanh DC:',
  timeToCharge:
    'Giả sử xe được sạc từ thời điểm pin trống bằng bộ sạc Cấp 2 cỡ trung bình (7,7 kW). Lưu ý: Bộ sạc Cấp 2 yêu cầu ổ cắm 240V. Thời gian sạc bằng bộ sạc Cấp 1 lâu hơn nhiều.',
  'ev.carDetails.batterySizeTooltip':
    'Tùy thuộc vào loại xe, một kilowatt-giờ (kWh) là đủ năng lượng để lái trong khoảng cách từ 1 đến 4 dặm. Pin máy tính xách tay thông thường có thể có công suất nhỏ hơn 0,1 kWh.',
  'ev.carDetails.treesPlanted': 'Số cây được trồng',
  'ev.carDetails.gasolineSaved': 'Số xăng tiết kiệm được',
  'ev.carDetails.compareText':
    'Chúng tôi đã chọn {gasVehicle} theo các thông số gần với {vehicle} dựa trên thương hiệu, kích thước và giá cả.',
  'ev.cardetails.calc.dcRate': 'Công suất sạc nhanh DC (DCFC)',
  'ev.cardetails.calc.EPA': 'Xếp hạng hiệu suất của EPA',
  'ev.cardetails.calc.chargingRate': 'Công suất sạc',
  'ev.cardetails.calc.vehicleBatteryCapacity': 'Dung lương pin của xe',
  'ev.cardetails.calc.maxACIntake': 'Đầu vào AC tối đa của xe',
  'ev.cardetails.calc.lvlTwoChargingRate': 'Công suất sạc Cấp 2',
  'ev.cardetails.calc.minOflvl2AC':
    'Công suất sạc Cấp 2 tối thiểu và đầu vào AC tối đa của xe',
  'ev.cardetails.calc.lvl2FullCharge': 'Thời gian sạc đầy bằng sạc Cấp 2',
  'ev.cardetails.calc.milesDrivenPerYr': 'Số dặm đi được mỗi năm',
  'ev.cardetails.calc.userInput': 'Dữ liệu của người dùng',
  'ev.cardetails.calc.milesPerGallon': 'Số dặm trên mỗi gallon (MPG)',
  'ev.cardetails.calc.mpg': ' dặm/gal',
  'ev.cardetails.calc.portionElectric': 'Tỷ lệ lái xe bằng điện',
  'ev.cardetails.calc.portionElectricSource':
    'Dữ liệu của người dùng. Đối với xe lai, chỉ ước tính số dặm di chuyển được bằng pin điện thay vì bằng xăng',
  'ev.cardetails.calc.gallonsUsed': 'Số gallon sử dụng',
  'ev.cardetails.calc.gallonsUsedFormula':
    'Số dặm di chuyển được * (1 - Khi lái xe bằng điện) / MPG',
  'ev.cardetails.calc.gallonsSaved': 'Số gallon tiết kiệm được',
  'ev.cardetails.calc.gallonsSavedFormula':
    'Số gallon Xe chạy bằng xăng tương đương sử dụng - Số gallon Xe điện sử dụng',
  'ev.cardetails.calc.electricEmissions': 'Lượng phát thải từ xe điện theo lb',
  'ev.cardetails.calc.electricEmissionsLbsMwh':
    'Lượng phát thải từ xe điện theo lb/MWh',
  'ev.cardetails.calc.EIA': 'Cơ quan Quản lý Thông tin Năng lượng Hoa Kỳ',
  'ev.cardetails.calc.gasolineEmissionsYr':
    'Lượng phát thải CO2 mỗi năm từ xe chạy bằng xăng theo lb',
  'ev.cardetails.calc.gasolineEmissions':
    'Lượng phát thải từ xe chạy bằng xăng theo lb/gal',
  'ev.cardetails.calc.emissionsReudctions': 'Giảm khí thải',
  'ev.cardetails.calc.lbsCo2PerYr': ' số lb CO2 mỗi năm',
  'ev.cardetails.calc.gasolineEmissionsLbs':
    'Lượng phát thải từ xe chạy bằng xăng theo lb',
  'ev.cardetails.calc.arborDayFoundation': 'Tổ chức Arbor Day',
  'ev.cardetails.calc.emissionsPerTree':
    'Lượng phát thải tiết kiệm được trên mỗi cây',
  'ev.cardetails.calc.electricityEmissions':
    'Lượng phát thải CO2 từ xe điện theo lb/MWh',
  'ev.usedCarTitle': '{car} cũ',
  'ev.cardetails.electricRange':
    'Cân nhắc đến sự xuống cấp của pin, phạm vi được trích dẫn ở đây được ước tính dựa trên độ tuổi của xe và thói quen lái/sạc xe thông thường.',
  'ev.carDetails.usedBatterySizeTooltip':
    'Tùy thuộc vào loại xe, một kilowatt-giờ (kWh) là đủ năng lượng để lái trong khoảng cách từ 1 đến 4 dặm. Cân nhắc đến sự xuống cấp của pin đối với xe điện cũ, kích thước pin được trích dẫn ở đây được ước tính dựa trên độ tuổi của xe và thói quen lái/sạc xe thông thường.',

  'ev.purchaseMethod': 'Phương thức mua',
  'ev.purchaseMethodToolTip':
    'Trong trường hợp thuê xe, bạn phải trả lại xe sau thời gian thuê. Trong trường hợp hỗ trợ trả góp, bạn sẽ giữ lại xe sau khi thanh toán tất cả khoản trả góp.',
  'ev.cash': 'Tiền mặt',
  'ev.loan': 'Trả góp',
  'ev.lease': 'Thuê',
  'ev.milesDrivenAnnually': 'Số dặm đi được mỗi năm',
  'ev.electricPortion': 'Số dặm xe điện đi được',
  'ev.electricPortionTooltip':
    'Xe điện lai cắm điện (PHEV) có thể sử dụng cả điện và xăng. Sử dụng điện thường có lợi hơn',
  'ev.yearsOwnership': 'Số năm sở hữu/lần thuê',
  'ev.interestRate': 'LÃI SUẤT THUÊ/TRẢ GÓP',
  'ev.electricityRateCalc':
    'Cước điện được tính bằng mức trung bình trong sáu tháng thấp điểm mùa hè và sáu tháng thấp điểm đối với Cước A, giá cước rẻ nhất hiện có cho mã bưu chính của bạn.',

  'assumption.salesTax': 'Thuế thương vụ',
  'assumption.gasolinePrice': 'Giá xăng',
  'assumption.downPayment': 'Tiền trả góp lần đầu',
  'assumption.electricityRate': 'Cước điện',
  'assumption.downPaymentValue': '10% MSRP + Thuế',

  'ev.compareGasVehicle':
    'So sánh {year} {car} với xe chạy bằng xăng tương tự {gasCar}',
  'ev.compareOtherVehicles': 'SO SÁNH CÁC LOẠI XE',
  'ev.incentives.title':
    'Có thể tiết kiệm tới {savings} nhờ tín dụng thuế và khoản giảm giá {lineBreak} cho {car}',
  'ev.reviews': 'Ý kiến đánh giá trên mạng',

  'chargingMap.updateZipcode': 'Cập nhật mã bưu chính',
  'chargingMap.errorZipcode':
    'Lỗi: {workingZipcode} không phải mã bưu chính hợp lệ',
  'chargingMap.showFullListOfStations': 'DANH SÁCH TRẠM ĐẦY ĐỦ',
  'chargingMap.chargerMapCopy':
    'Dữ liệu từ Trung tâm dữ liệu nhiên liệu thay thế có thể không phản ánh tính sẵn có hiện tại. Gửi các trạm sạc nghi ngờ bị thiếu {link}. Vui lòng xác nhận tình trạng sẵn có của các trạm sạc trước khi bắt đầu hành trình của bạn',
  'chargingMap.routeMapCopy':
    'Phạm vi quãng đường dựa trên ước tính do nhà sản xuất xe điện cung cấp. Phạm vi của bạn có thể thay đổi tùy theo khí hậu, khu vực, giao thông, model và trạng thái của ô tô.',
  'chargingMap.public': 'Trạm công cộng',
  'chargingMap.businessGovernment': 'Bộ sạc Cấp 2',
  'chargingMap.highPowerStations': 'Trạm điện cao thế',
  'chargingMap.DCChargers': 'Bộ sạc nhanh DC',
  'chargingMap.otherSttations': 'Các trạm khác',
  'chargingMap.privateStations': 'Trạm tư nhân',
  'chargingMap.searchDealers': 'Tìm kiếm các đại lý đạt chuẩn',
  'chargingMap.otherStations': 'Các trạm khác',
  'chargingMap.searchCharging': 'Tìm kiếm trạm sạc',

  'homepage.chargingMap.title': 'Sạc trên đường dù bạn đang ở bất cứ đâu.',
  'homepage.chargingMap.subtitle':
    ' Với các trạm mới được cấp nguồn mỗi ngày, lái xe trên quãng đường dài và sạc pin cho xe trở nên dễ dàng hơn bao giờ hết. Nhập vị trí để xem tất cả các trạm sạc gần bạn.',

  'page.chargingMap.title': 'Trạm sạc',
  'page.chargingMap.subtitle':
    'Sạc pin khi di chuyển trở nên dễ dàng hơn bao giờ hết. Nhập vị trí để xem tất cả các trạm sạc gần bạn hoặc trên tuyến đường của bạn.',

  'radiusMap.address': 'ĐỊA CHỈ',
  'radiusMap.vehicle': 'XE',
  viewTravelRadius: 'Xem bán kính di chuyển',
  radiusSpecification: 'tổng số dặm khi đầy pin',

  'dealers.brands': 'Thương hiệu',
  'dealers.allBrands': 'Tất cả thương hiệu',
  'dealers.allDealers': 'Tất cả đại lý',
  'dealers.contact': 'LIÊN HỆ',
  'dealers.website': 'TRANG WEB',
  'dealers.findDealers': 'Tìm đại lý',

  'incentives.title': 'Ưu đãi bộ sạc và xe điện',
  'incentives.title.page': 'ƯU ĐÃI XE ĐIỆN',
  'incentives.disclaimer':
    'Tính đủ điều kiện và số tiền ưu đãi không phát sinh thuế và tư vấn pháp lý. Tham khảo ý kiến của bên cấp ưu đãi và/hoặc chuyên gia thuế hoặc pháp lý của bạn để xác định tính đủ điều kiện, số tiền ưu đãi hoặc giảm giá được cung cấp cụ thể.',
  'incentives.subTitle':
    'Bạn có thể đủ điều kiện nhận một loạt ưu đãi, bao gồm giảm giá, tín dụng thuế và nhiều quyền lợi khác. Điều chỉnh bộ lọc để cá nhân hóa kết quả của bạn.',
  'incentives.single': 'Độc thân',
  'incentives.married': 'Đã kết hôn',
  'incentives.headOfHousehold': 'Chủ hộ',
  'incentives.vehicleType': 'Loại xe',
  'incentives.vehicleTypeTooltip':
    'Khoản ưu đãi nhất định phụ thuộc vào loại xe bạn mua',
  'incentives.tradeIn': 'Ngừng sử dụng xe',
  'incentives.income': 'Trạng thái thuế và thu nhập',
  'incentives.incomeTooltip':
    'Khả năng hội đủ điều kiện nhận ưu đãi và số tiền bạn có thể nhận thường phụ thuộc vào thu nhập của bạn. Khách hàng có thu nhập thấp hơn thường đủ điều kiện để nhận khoản giảm giá cao hơn.',
  'incentives.clunkerNone':
    'Một số ưu đãi nhất định sẽ hoàn tiền cho bạn khi ngừng sử dụng chiếc xe hiện tại, còn được gọi là xe cũ.',
  'incentives.clunkertoolTip':
    'Một số ưu đãi nhất định sẽ hoàn tiền cho bạn khi ngừng sử dụng chiếc xe hiện tại, còn được gọi là xe cũ. Đặc biệt, ',
  'incentives.clunkertoolTipContd':
    ' có sẵn trong khu vực của bạn. Yêu cầu về tính đủ điều kiện sẽ thay đổi tùy theo ưu đãi; vui lòng kiểm tra chi tiết chương trình.',
  'incentives.householdSize': 'Quy mô hộ gia đình',
  'incentives.householdIncome': 'Thu nhập hộ gia đình',
  'incentives.selectProvider': 'Nhà cung cấp điện',
  'incentives.planClunker': 'Bạn đang lên kế hoạch gửi lại xe cũ?',
  'incentives.location': 'Địa điểm',
  'incentives.locationTooltip':
    'Nhiều ưu đãi phụ thuộc vào nơi bạn sống. Nhập mã bưu chính của bạn để xác định những ưu đãi có sẵn ở nơi bạn sống.',
  'incentives.taxFilingStatus': 'Tình trạng nộp thuế',
  'incentives.whichVehicle': 'Bạn định mua loại xe nào?',
  'incentives.incentiveEligibility':
    'Cập nhật tính đủ điều kiện để nhận ưu đãi',
  'incentives.qualify':
    'Bạn có thể đủ điều kiện nhận tới {sum} đô la tiền tiết kiệm từ tín dụng thuế xe và giảm giá.',

  'homeChargers.subText':
    'Chọn loại xe bạn quan tâm để xem thời gian sạc cụ thể cho đến khi đầy pin.',
  'homeChargers.wallMounted': 'Gắn tường',
  'homeChargers.portable': 'Di động',
  'homeChargers.price': 'Giá',
  'homeChargers.cordLength': 'Chiều dài dây',
  'homeChargers.wifi': 'Kết nối WiFi',
  'homeChargers.socket': 'ổ cắm',
  'homeChargers.socketTooltip':
    'Các model bộ sạc khác nhau có kiểu cắm khác nhau. Những kiểu cắm này tương ứng với cách các chân cắm vừa khít với ổ cắm trên tường của bạn. Nếu bạn chưa chắc chắn kiểu cắm nào phù hợp nhất với nhà/nhà xe của mình, hãy tham khảo ý kiến của thợ điện có chuyên môn.',
  'homeChargers.cordLengthLtH': 'Chiều dài dây: Thấp đến cao',
  'homeChargers.cordLengthHtL': 'Chiều dài dây: Cao đến thấp',
  'homeChargersCard.buy': 'Chi tiết & Mua',
  'homeChargersCard.beforeIncentives': 'Trước ưu đãi',
  'homeChargersCard.socket': 'Ổ cắm {socket}',
  'homeChargers.cable': 'dây {length} ft',
  'homeChargers.hardwired': 'Kết nối có dây',
  'homeChargers.toFullCharge': 'đến khi sạc đầy',

  'electricians.residential': 'Dân dụng',
  'electricians.commercial': 'Thương mại',
  'electricians.industrial': 'Công nghiệp',
  'electricians.type': 'Loại thợ điện',
  'electricians.searchElectricians': 'Tìm kiếm thợ điện đạt chuẩn',

  'tabbed-maps-dealer-catalog-tab': 'Đại lý',
  'tabbed-maps-all-stations-tab': 'Trạm sạc',
  'tabbed-maps-electricians-tab': 'Thợ điện',
  'tabbed-maps-route-tab': 'trình chỉ đường',
  'tabbed-maps-range-tab': 'Bản đồ phạm vi',

  events: 'Sự kiện',
  'events.subHeader':
    'Trực tiếp sở hữu một chiếc xe điện để biết lý do xe điện được ưa chuộng đến vậy.',
  eventsFilters: 'Bộ lọc',
  eventsDateRange: 'Phạm vi ngày tháng',
  'events.nationWide': 'Toàn quốc',
  'events.both': 'Cả hai',
  'events.inPerson': 'Trực tiếp',
  'events.virtual': 'Trực tuyến',
  'events.rideAndDrive': 'Lái xe',
  'events.autoshow': 'Triển lãm ô tô',
  'events.educationEvent': 'Sự kiện giáo dục',
  'events.training': 'Đào tạo',
  'events.webinar': 'Hội thảo trực tuyến',
  'events.conference': 'Hội nghị',
  'events.distance': 'Khoảng cách',
  'events.inPersonOrVirtual': 'Trực tiếp hoặc trực tuyến',
  'eventCard.inPerson': 'Sự kiện trực tiếp',
  'eventCard.virtualEvent': 'Sự kiện trực tuyến',
  'eventCard.inPersonAndVirtual': 'Sự kiện trực tiếp + trực tuyến',
  'eventCard.learnMore': 'Tìm hiểu thêm',
  'eventCard.hostedBy': 'Chủ trì bởi',
  'eventSort.dateLtH': 'Ngày (Sớm nhất trước)',

  'inventory-filter.filter-vehicles': 'Bộ lọc',
  'inventory-filter.model-label': 'Người mẫu',
  'inventory-card.vehicle-details': 'Xem chi tiết',
  'evs.inventorythirdParty':
    '* Trang web này chứa các liên kết đến trang web bên ngoài của bên thứ ba. APS không xác nhận hoặc chấp nhận bất kỳ trách nhiệm pháp lý nào đối với nội dung hoặc sản phẩm được bán trên các trang web của bên thứ ba. APS không có quyền kiểm soát và không chịu trách nhiệm về nội dung, chính sách quyền riêng tư hoặc phương thức hoạt động của các trang web bên ngoài bên thứ ba. Khi nhấp vào liên kết, bạn đồng ý miễn trừ APS khỏi mọi trách nhiệm pháp lý phát sinh từ hoạt động sử dụng của bạn hoặc từ nội dung trên bất kỳ trang web bên ngoài nào của bên thứ ba.',
  toolGeneralDisclaimer:
    'Đây là trang web của bên thứ ba do ZappyRide sở hữu, kiểm soát và quản lý, không phải Công ty Arizona Public Service (APS). ZappyRide có thể chia sẻ hoặc cung cấp quyền truy cập vào các Thông tin phi cá nhân liên quan đến hoạt động vận hành và quản lý Trang web của ZappyRide cho APS. Chính sách bảo mật của APS quản lý việc thu thập, tiếp nhận và sử dụng thông tin đó trên aps.com và có sẵn tại đây.',
  vehicleType: 'Chọn tất cả loại thân xe mà bạn quan tâm.',
  householdIncome:
    'Tổng hợp thu nhập của tất cả thành viên trong gia đình. Sử dụng để xác định tính đủ điều kiện nhận ưu đãi.',
  homeChargerAvailability:
    'Sạc xe điện tại nhà là phương án thuận tiện và tiết kiệm chi phí nhất. Nếu không thể sạc tại nhà, chúng tôi sẽ ước tính chi phí sạc công cộng. Lưu ý: Bộ sạc Cấp 1 chạy trên điện áp tiêu chuẩn 120V, Cấp 2 chạy trên điện áp 240V.',
  householdSize:
    'Bao gồm tất cả các thành viên trong gia đình bạn (vợ chồng, người phụ thuộc, v.v.). Sử dụng để xác định tính đủ điều kiện nhận ưu đãi.',
  taxFilingStatus: 'Sử dụng để xác định tính đủ điều kiện nhận ưu đãi.',
  retireAVehicle:
    'Bạn có thể phải ngừng sử dụng xe chạy bằng xăng cũ hoặc hiện có để nhận một số ưu đãi nhất định.',
  batterySize:
    'Tùy thuộc vào loại xe, một kilowatt-giờ là đủ năng lượng để lái trong khoảng cách từ 1 đến 4 dặm. Để tham khảo, pin máy tính xách tay thông thường có thể có công suất nhỏ hơn 0,1 kWh.',
  electricMilesDriven:
    'Ước tính phần trăm quãng đường lái xe của bạn khi chạy hoàn toàn bằng điện. Nếu bạn sạc pin hàng ngày và lái xe ít hơn phạm vi chạy hoàn toàn bằng điện mỗi ngày, tỷ lệ phần trăm của bạn sẽ đạt gần 100%. Đối với hầu hết hoạt động lái xe thông thường ngoài phạm vi chạy hoàn toàn bằng điện, tỷ lệ giả định sẽ là 50%.',
  graphDisclaimerPrice:
    'Giá mua xe điện có thể cao hơn so với giá mua xe chạy bằng động cơ đốt trong tương tự. Tuy nhiên, một số chi phí trả trước cao hơn liên quan đến xe điện có thể được hoàn lại qua các ưu đãi xe, tổng chi phí sở hữu thấp hơn và giá trị bán lại cao hơn.',
  graphDisclaimerElectricity:
    'Nạp nhiên liệu bằng điện có thể tốn ít chi phí hơn nạp nhiên liệu bằng xăng trên cùng một khoảng cách di chuyển.',
  graphDisclaimerGas: 'Chi phí nhiên liệu cho xăng thường cao hơn điện.',
  graphDisclaimerMaintenance:
    'Xe điện thường có chi phí bảo trì thường thấp hơn do có ít bộ phận chuyển động hơn.',
  graphDisclaimerInsurance:
    'Xe điện có thể tốn nhiều tiền bảo hiểm hơn do giá mua cao hơn. Chi phí bảo hiểm cao hơn có thể được hoàn lại bằng các khoản tiết kiệm nhiên liệu và bảo trì.',
  graphDisclaimerCostAnalysis:
    'Số liệu so sánh phản ánh số tiền tiết kiệm xấp xỉ và không được đảm bảo. Số tiền tiết kiệm thực tế sẽ thay đổi tùy theo nhu cầu sử dụng cá nhân và điều kiện thị trường. APS không tuyên bố hay bảo đảm dưới bất kỳ hình thức nào, rõ ràng hay ngụ ý, đối với độ chính xác của thông tin trong tài liệu này.',
  costAnalysisDisclaimer:
    'Số liệu so sánh phản ánh số tiền tiết kiệm xấp xỉ và không được đảm bảo. Số tiền tiết kiệm thực tế sẽ thay đổi tùy theo nhu cầu sử dụng cá nhân và điều kiện thị trường. APS không tuyên bố hay bảo đảm dưới bất kỳ hình thức nào, rõ ràng hay ngụ ý, đối với độ chính xác của thông tin trong tài liệu này.',
  rangeMap:
    'Như quảng cáo từ nhà sản xuất. Phạm vi thực tế có thể thay đổi, tùy thuộc vào yếu tố môi trường, hành vi lái xe, bảo dưỡng xe và tuổi thọ của pin.',

  tcoSavingsHeading:
    '{car1} hiện không rẻ hơn chi phí sở hữu chiếc xe hiện tại của bạn',

  here: 'ở đây',
  any: 'bất kỳ',
  new: 'Duyệt tìm tất cả xe điện',
  localInventory: 'Kho hàng tại địa phương',
  preOwned: 'Mua sắm từ kho hàng tại địa phương',
  preOwnedElectricVehicles: 'Xe điện đã qua sử dụng',
  'usedEVCard.yearRange': 'Phạm vi năm',
  'usedEVCard.electricRangeAverage': 'Phạm vi chạy điện trung bình',
  gasolinePriceLabel: 'Giá xăng ($/gal)',
  incentivesHeader:
    'Khám phá các khoản ưu đãi, giảm giá và tín dụng khi mua và thuê xe điện',
  incentivesSubHeader:
    'Bạn có thể giảm chi phí trả trước của xe điện bằng các khoản ưu đãi, tín dụng thuế và {lineBreak} giảm giá đặc biệt tiềm năng dành cho khách hàng của Xcel Energy.',
  billCredit: 'Tín dụng hóa đơn',
  discountedElectricRate: 'Cước điện được chiết khấu',
  financing: 'Hỗ trợ tài chính',
  freeEquipment: 'Thiết bị miễn phí',
  hovLane: 'Làn HOV',
  insuranceDiscount: 'Chiết khấu bảo hiểm',
  parkingExemption: 'Miễn phí đỗ xe',
  publicCharging: 'Sạc công cộng',
  rebate: 'Giảm giá',
  taxCredit: 'Tín dụng thuế',
  taxExemption: 'Miễn thuế',
  tollDiscount: 'Chiết khấu thuế qua đường',
  home: 'Nhà riêng',
  feet: 'foot',
  month: 'tháng',
  electricVehicle: 'Xe điện',
  maximum: 'tối đa',
  details: 'Chi tiết',
  'homepage.vehicleCarousel.compareVehiclesButton': 'TÌM XE ĐIỆN CỦA BẠN',
  'evs.newButton': 'MỚI',
  'evs.usedButton': 'ĐÃ SỬ DỤNG',
  'ev.carDetails.view.back': 'TẤT CẢ XE CỘ',
  'ev.electricityRate': 'GIÁ ĐIỆN ($/kWh)',
  'graph.costToBreakdown.subTitleCheaper': '{car1} trở nên rẻ hơn sau {time}',
  'evs.flipBack': 'Lật lại',
  'evs.moreInfo': 'Thêm thông tin',
  'evs.flipDesc': 'Lật để mô tả',
  'map.input.highPower': 'Chỉ các trạm điện cao thế',
  'map.input.startLocation': 'Vị trí bắt đầu',
  'map.input.endLocation': 'KẾT THÚC Vị trí',
  'map.input.includeCharge': 'Bao gồm các trạm sạc bên trong',
  'map.input.mapRoute': 'BẢN ĐỒ TUYẾN ĐƯỜNG',

  'travelRoute.OneMile': 'Trong vòng 1 dặm',
  'travelRoute.TwoMiles': 'Trong vòng 2 dặm',
  'travelRoute.FiveMiles': 'Trong vòng 5 dặm',

  'footer.links.title': 'Bộ Phận Năng Lượng Cộng Đồng',
  'footer.links.contact': 'Liên Hệ Chúng Tôi',
  'footer.links.join': 'Tham Gia Đội Ngũ Của Chúng Tôi',
  'footer.links.media': 'Truyền Thông',
  'footer.links.opt': 'Từ Chối',
  'footer.links.customer': 'Bảo Mật Khách Hàng',
  'footer.links.policy': 'Chính Sách',
  'footer.links.sanJose': 'Năng Lượng San José',
  'footer.bottom.rights': ' Tất Cả Các Quyền Được Bảo Lưu',
  'footer.bottom.provide1': 'Các giải pháp EV được cung cấp bởi',
  'footer.bottom.provide2': 'một doanh nghiệp của J.D. Power',
  'footer.bottom.text':
    'Các số liệu được hiển thị trên trang này là ước tính dựa trên dữ liệu do bên thứ ba cung cấp. Tài liệu này chỉ được cung cấp cho mục đích thông tin và không nên dựa vào đó để lấy lời khuyên về thuế, pháp lý hoặc tài chính. J.D. Power® cũng như San Jose Clean Energy không cung cấp lời khuyên về thuế, pháp lý hoặc tài chính. Bạn nên tự xác minh các ước tính được cung cấp tại đây trước khi tham gia vào bất kỳ giao dịch nào.',
};
