import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LinkCard from '../../LinkCard/LinkCard';


const HomepageBigPromise = () => {
  return (
    <section className="white">
      <div className="homepage-jumbotron">
        <div className="homepage-jumbotron-text">
          <h1 className="title-banner">
            <FormattedMessage
              id="homepage.banner.title"
              defaultMessage="Your guide to EVS. "
              description="Your guide to EVS. "
            />
          </h1>
          <span className="title-banner-end">
              <FormattedMessage
                id="homepage.banner.title.end"
                defaultMessage="Estimate and compare costs, savings, incentives & more"
                description="Estimate and compare costs, savings, incentives & more"
              />
          </span>
        </div>
      </div>
      <div id="link-card-buttons">
        <div className="col">
          <div className="link-card-container">
            <LinkCard type="DISCOVER_ELECTRIC_VEHICLES" />
            <LinkCard type="MAXIMIZE_INCENTIVES" />
            <LinkCard type="LOCATE_CHARGING_STATIONS" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
  userLocation: PropTypes.string,
};
