import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import useMapBounds from '../../../hooks/useMapBounds';
import useSelectedStation from '../../../hooks/useSelectedStation';
import useChargingStationsNearRoute from '../../../hooks/useChargingStationsNearRoute';

import setupGoogleMapsAutocomplete from '../../../utils/setupGoogleMapsAutocomplete';
import isSuperChargingStation from '../../../utils/predicates/isSuperChargingStation';

import BaseGoogleMapReact from '../BaseGoogleMapReact/BaseGoogleMapReact';
import MapControlPanel from '../MapControlPanel/MapControlPanel';
import Checkbox from '../../shared/InputElements/Checkbox';
import useGeoJsonUrls from '../../../hooks/useGeojsonUrls';

import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import './TravelRouteMap.scss';
import ChargingStationsMapLegend from '../ChargingStationsMapLegend/ChargingStationsMapLegend';

const overviewPathAsLinestring = (overviewPath) => {
  const coordsAsText = overviewPath
    .map((coords) => `${coords.lng()} ${coords.lat()}`)
    .join(', ');
  return `LINESTRING(${coordsAsText})`;
};

const TravelRouteMap = ({
  chargingStationsFilterFn,
  isVisible = true,
  pspsEvents = [],
}) => {
  const [distance, setDistance] = useState(2);
  const [startAddress, setStartAddress] = useState(
    process.env.REACT_APP_PAGES_MAP_ROUTE_START_ADDRESS ||
      'San Jose City Hall, 200 E Santa Clara St, San Jose, CA 95112, USA'
  );
  const [destinationAddress, setDestinationAddress] = useState(
    process.env.REACT_APP_PAGES_MAP_ROUTE_END_ADDRESS || 'Los Angeles, CA, USA'
  );
  const [isFetchingRoute, setIsFetchingRoute] = useState(false);
  const [routeError, setRouteError] = useState();
  const [isIgnoringLowPowerStations, setIsIgnoringLowPowerStations] =
    useState(false);
  const [routeDistanceInMeters, setRouteDistanceInMeters] = useState();
  const userPrefs = useContext(UserPrefsContext);

  const directionsServiceRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const autocompleteStartLocationRef = useRef();
  const autocompleteEndLocationRef = useRef();
  const intl = useIntl();
  useEffect(() => {
    const stationAddress = localStorage.getItem('plan-route-station');

    if (stationAddress) {
      setDestinationAddress(stationAddress);
      localStorage.removeItem('plan-route-station');
    }
  }, []);

  const { registerMapBoundsListeners, filterWithinBounds } = useMapBounds();

  const {
    chargingStations,
    fetchChargingStations,
    error: chargingStationsError,
  } = useChargingStationsNearRoute();

  const [selectedStation, selectStation] = useSelectedStation(chargingStations);

  const registerGeojsonUrlMap = useGeoJsonUrls(
    pspsEvents.flatMap((event) => event.file_urls)
  );

  const onCompleteStartLocation = () => {
    const address =
      autocompleteStartLocationRef.current.getPlace().formatted_address;
    setStartAddress(address);
  };

  const onCompleteEndLocation = () => {
    const address =
      autocompleteEndLocationRef.current.getPlace().formatted_address;
    setDestinationAddress(address);
  };

  const saveMapRefs = ({ map }) => {
    /* eslint-disable no-undef */
    directionsServiceRef.current = new google.maps.DirectionsService();
    directionsRendererRef.current = new google.maps.DirectionsRenderer();
    /* eslint-enable no-undef */

    setupGoogleMapsAutocomplete(
      autocompleteStartLocationRef,
      'travel-route-map-start-location',
      onCompleteStartLocation
    );
    setupGoogleMapsAutocomplete(
      autocompleteEndLocationRef,
      'travel-route-map-end-location',
      onCompleteEndLocation
    );

    directionsRendererRef.current.setMap(map);
    registerMapBoundsListeners(map);
    fetchRoute({ isTrackingEvent: false });

    registerGeojsonUrlMap(map);
  };

  const isSubmittingAllowed = () =>
    !isFetchingRoute && startAddress && destinationAddress;

  const fetchRoute = () => {
    if (!startAddress || !destinationAddress) return;
    setIsFetchingRoute(true);
    setRouteError(undefined);
    setRouteDistanceInMeters(undefined);

    directionsServiceRef.current.route(
      {
        origin: startAddress,
        destination: destinationAddress,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRendererRef.current.setDirections(response);

          if (response.routes && response.routes.length > 0) {
            // See https://developer.nrel.gov/docs/transportation/alt-fuel-stations-v1/nearby-route/
            const linestring = overviewPathAsLinestring(
              response.routes[0].overview_path
            );

            fetchChargingStations({ route: linestring, distance });
            const distanceInMeters = response.routes[0].legs.reduce(
              (totalDistance, leg) => totalDistance + leg.distance.value,
              0
            );
            setRouteDistanceInMeters(distanceInMeters);
          }
        } else {
          setRouteError('Error fetching route');
        }
        setIsFetchingRoute(false);
      }
    );
  };

  useEffect(() => {
    if (!directionsServiceRef.current) return;

    fetchRoute({ isTrackingEvent: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distance]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isSubmittingAllowed()) {
      fetchRoute({ isTrackingEvent: false });
    }
  };

  const visibleChargingStations = filterWithinBounds(chargingStations).filter(
    (station) =>
      isIgnoringLowPowerStations ? isSuperChargingStation(station) : true
  );
  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  const powerFilteredChargingStations =
    userPrefs.get('vehicleTypeFilter') === 'All'
      ? filteredChargingStations
      : filteredChargingStations.filter((station) =>
          userPrefs.get('vehicleTypeFilter') === 'highPower'
            ? Number(station.ev_dc_fast_num)
            : userPrefs.get('vehicleTypeFilter') === 'public'
            ? station.access_code === 'public'
            : station.access_code !== 'public'
        );

  return (
    <div className="TravelRouteMap">
      <div className="col-sm-12">
        <MapControlPanel
        // selectedStation={selectedStation}
        // chargingStations={filteredChargingStations}
        >
          <div className="form-group">
            <label
              htmlFor="travel-route-map-start-location"
              style={{ color: '#A0A0A0' }}
            >
              {intl.formatMessage({
                id: 'map.input.startLocation',
                defaultMessage: 'Start Location',
              })}
            </label>
            <input
              id="travel-route-map-start-location"
              type="text"
              className="form-control"
              onChange={(e) => setStartAddress(e.target.value)}
              value={startAddress}
              placeholder="Start Location"
              disabled={isFetchingRoute}
              onKeyPress={handleKeyPress}
              style={{ height: '42px' }}
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="travel-route-map-end-location"
              style={{ color: '#A0A0A0' }}
            >
              {intl.formatMessage({
                id: 'map.input.endLocation',
                defaultMessage: 'End Location',
              })}
            </label>
            <input
              id="travel-route-map-end-location"
              type="text"
              className="form-control"
              onChange={(e) => setDestinationAddress(e.target.value)}
              value={destinationAddress}
              placeholder="End Location"
              disabled={isFetchingRoute}
              onKeyPress={handleKeyPress}
              style={{ height: '42px' }}
            />
          </div>
          <div className="form-group col-lg-2.5">
            <label
              className="label-subTitle"
              htmlFor="travel-route-map-select-radius"
              style={{ color: '#A0A0A0' }}
            >
              {intl.formatMessage({
                id: 'map.input.includeCharge',
                defaultMessage: 'Include Charging stations within',
              })}
            </label>
            <select
              id="travel-route-map-select-radius"
              className="form-control"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              style={{ height: '42px' }}
            >
              <option value={1}>
                {intl.formatMessage({
                  id: 'travelRoute.OneMile',
                  defaultMessage: 'Within 1 mile',
                })}
              </option>
              <option value={2}>
                {intl.formatMessage({
                  id: 'travelRoute.TwoMiles',
                  defaultMessage: 'Within 2 miles',
                })}
              </option>
              <option value={5}>
                {intl.formatMessage({
                  id: 'travelRoute.FiveMiles',
                  defaultMessage: 'Within 5 miles',
                })}
              </option>
            </select>
          </div>

          <div className="form-group checkbox col-lg-2.5">
            <Checkbox
              id="show-only-supercharging-stations-route"
              value={isIgnoringLowPowerStations}
              label={intl.formatMessage({
                id: 'map.input.highPower',
                defaultMessage: 'High Power Stations Only',
              })}
              handler={(e) =>
                setIsIgnoringLowPowerStations((currentValue) => !currentValue)
              }
            />
          </div>
          <div className="form-group last-item col-lg-2">
            <button
              className="btn btn-aps btn-detail"
              type="button"
              onClick={fetchRoute}
              disabled={!isSubmittingAllowed()}
            >
              {intl.formatMessage({
                id: 'map.input.mapRoute',
                defaultMessage: 'Map Route',
              })}
            </button>
          </div>
          {chargingStationsError && (
            <p className="text-danger">
              There was an error fetching charging stations. Please try again.
            </p>
          )}
          {routeError && (
            <p className="text-danger">
              We could not find that route. Please check the addresses and try
              again.
            </p>
          )}
        </MapControlPanel>
      </div>

      <div className="col-sm-12">
        <div className="map-container">
          {routeDistanceInMeters && (
            <div className="route-distance-legend">
              This route is {Math.ceil(routeDistanceInMeters / 1609)} miles
            </div>
          )}
          {isVisible && (
            <BaseGoogleMapReact
              onGoogleApiLoaded={saveMapRefs}
              onHoverOnStation={selectStation}
              onClickMarker={() => {}}
              chargingStations={filteredChargingStations}
              selectedStation={selectedStation}
              onChange={() => {}}
            />
          )}
        </div>
      </div>

      <div className="col-sm-12">
        <ChargingStationsMapLegend
          chargingStations={powerFilteredChargingStations}
        />
      </div>
      <div>
        <p style={{ margin: '8px 0' }}>
          {intl.formatMessage({
            id: 'chargingMap.routeMapCopy',
            defaultMessage:
              'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model and automobile conditions.',
          })}
        </p>
      </div>
      {/* <div className="col-sm-12">
        <ChargingStationsList chargingStations={filteredChargingStations} />
      </div> */}
    </div>
  );
};

TravelRouteMap.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default TravelRouteMap;
