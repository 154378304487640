 import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from "react-intl";

 
const EVFilterType = () => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");

  const updateFormFactorFilter = (e, key) => {
    e.preventDefault();
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];
    userPrefs.set({
      vehicleFormFactorFilters: newFilters,
    });
  };

 const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
         `${filterKey.charAt(0).toLowerCase()}${filterKey.substr(1)}`;

      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn active" : "btn"}
          key={i}
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={(e) => updateFormFactorFilter(e, filterKey)}
        >
          {intl.formatMessage
            ? intl.formatMessage({
                id: `vehicle.type.${filterName}`,
              })
            : `${filterName}`}
        </button>
      );
    }
  );

  return (
    <div className="form-group">
        <span className="label-style">
            <FormattedMessage
                id="evfilter.type"
                defaultMessage="Type"
                description="Type"
            />
        </span>
        <div className="btn-grid-container">{renderedFormFactorFilters}</div>
    </div>)
}

export default EVFilterType;
