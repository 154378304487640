import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import Range from '../../shared/InputElements/Range';
import { useIntl } from 'react-intl';



const SlideDownPayment = ({
  id = 'down-payment-range',
  ...rest
}) => {
  const intl = useIntl();
  const label = intl.formatMessage
    ? intl.formatMessage({
        id: 'assumption.downPayment',
        defaultMessage: 'Down Payment',
      })
    : 'Down Payment';

  const userPrefs = useContext(UserPrefsContext);
  const description = (val) => {
    return `${val}% ${
      intl.formatMessage
        ? intl.formatMessage({ id: 'ev.msrp', defaultMessage: 'of MSRP + Tax' })
        : 'of MSRP + Tax'
    }`;
  };
  


  return (
    <Range
      id={id}
      value={(userPrefs.get('downPayment'))}
      ariaLabel="Down Payment"
      label={label}
      rangeMin={5}
      rangeMax={40}
      rangeStep={1}
      description={description}
      handler={(e) => userPrefs.set({ downPayment: e.target.value })}
      {...rest}
    />
  );
};

export default SlideDownPayment;

SlideDownPayment.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
};
