import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";

import EVCatalog from "./../../client_customizations/components/EVCatalog/EVCatalog";
import MatchScoreOptions from "./../../client_customizations/components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "./../../components/EvSortControls/EvSortControls";

import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from "react-router-dom";

const EVs = ({ electricVehicles, ip, uuid, page }) => {
  const intl = useIntl()

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_VEHICLES_TITLE;
  });

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <MatchScoreOptions />
    </>
  );


  return (
    <section className="container-fluid" id="EVs">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 text-center">
            <h1 className="title-header" style={{fontWeight: 700}}>
              <FormattedMessage
                id="evsCatalog.title"
                defaultMessage="DISCOVER NEW ELECTRIC VEHICLES"
                description="Electric Vehicles"
              />
            </h1>

            <div className="container-row">
              <span />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <div className="vehicle-type-filter">
              <button id='new-vehicle' >
                {
                  intl.formatMessage({
                    id: "evs.newButton",
                    defaultMessage: "NEW",
                  })
                }
              </button>
              <Link to={`/used-vehicles`}>
                <button id='used-vehicle' >
                  {
                    intl.formatMessage({
                      id: "evs.usedButton",
                      defaultMessage: "USED",
                    })
                  }
                </button>
              </Link>
            </div>
            <div className="row ">
              <div className="col">
                <EvSortControls />
              </div>
            </div>
            <ModalComponent
              buttonText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "evs.buttonMatchScoreAndFilters",
                      defaultMessage: "OPEN FILTERS",
                    })
                  : "OPEN FILTERS"
              }
              titleText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "evs.matchScoreAndFilters",
                      defaultMessage: "Refine Match Score and Filters",
                    })
                  : "Refine Match Score and Filters"
              }
              isFullScreen
            >
              {renderOptions}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            <div className="vehicle-type-filter">
              <button id='new-vehicle' >
              {
                  intl.formatMessage({
                    id: "evs.newButton",
                    defaultMessage: "NEW",
                  })
                }
              </button>
              <Link to={`/used-vehicles`}>
                <button id='used-vehicle' >
                {
                    intl.formatMessage({
                      id: "evs.usedButton",
                      defaultMessage: "USED",
                    })
                  }
                </button>
              </Link>
            </div>
            {renderOptions}
          </div>
          <div
            id="electric-vehicles-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>Results have been updated as of {time}.</span>
          </div>
          <div className="col-xl-9 col-lg-8 col-sm-12" style={{ padding: 0 }}>
            <div className="row ">
              <div className="col">
                <div className="pull-right d-none d-lg-block">
                  <EvSortControls />
                </div>
              </div>
            </div>
            <EVCatalog
              vehicles={electricVehicles}
              hasEvDetails
              hasIncentivesAndMatchScore
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVs;

EVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
