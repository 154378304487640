import React from "react";
import PropTypes from "prop-types";

import ChargingPinHighPower from "./../../../client_customizations/assets/images/icons/charger-pin-high-power.png";
import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/charger-pin-public.png";
import ChargingPinDefault from "./../../../client_customizations/assets/images/icons/charger-pin-private.png";
import isSuperChargingStation from "../../../utils/predicates/isSuperChargingStation";
import { FormattedMessage } from "react-intl";

import "./ChargingStationsMapLegend.scss";

const ChargingStationsMapLegend = ({ chargingStations, vertical }) => {
  const hasSuperChargerStations = (chargingStations || []).some((station) =>
    isSuperChargingStation(station)
  );

  return (
    <div className={vertical ? "legend ChargingStationsMapLegend vertical" : "legend ChargingStationsMapLegend"}>
      {hasSuperChargerStations && (
        <div className="charger-type">
          <img src={ChargingPinHighPower} alt="" />
          
          <div className="textContainer">
            <b>
              <FormattedMessage
                id="chargingMap.highPowerStations"
                defaultMessage="High Power Stations"
                description="High Power Stations Text"
              />:
            </b>
            <span>
              <FormattedMessage
                id="chargingMap.DCChargers"
                defaultMessage="DC fast charge"
                description="DC fast charge or superchargers Text"
              />
            </span>
          </div>
        </div>
      )}
      <div className="charger-type">
        <img src={ChargingPinPublic} alt="" />
        <div className="textContainer">
          <b>
            <FormattedMessage
              id="chargingMap.public"
              defaultMessage="Public Stations"
              description="Public Stations"
            />:
          </b>
          <span>
            <FormattedMessage
              id="chargingMap.businessGovernment"
              defaultMessage="Level 2 chargers"
              description="Level 2 chargers"
            />
          </span>
        </div>
      </div>
      <div className="charger-type">
        <img src={ChargingPinDefault} alt="" />
        <div className="textContainer">
          <b>
            <FormattedMessage
              id="chargingMap.otherStations"
              defaultMessage="Other Stations"
              description="Other Types of Stations"
            />:
          </b>
          <span>
            <FormattedMessage
              id="chargingMap.privateStations"
              defaultMessage="Private Stations"
              description="Private Stations"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array,
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: [],
};

export default ChargingStationsMapLegend;
