import React from 'react';
import PropTypes from 'prop-types';

import VehicleImage from '../../../components/VehicleImage/VehicleImage';
import PricePanels from '../../../components/EVJumbotron/PricePanels/PricePanels';
import {  FormatCarName } from '../../../utils/Helpers/Format';
import { FormattedMessage } from 'react-intl';
import UsedVehiclePanels from '../../../components/EVJumbotron/UsedVehiclePanels/UsedVehiclePanels';

import vector from '../../../client_customizations/assets/images/icons/vector.svg';
import SmoothScroll from '../../../utils/Helpers/SmoothScroll';
import CarDetailsTable from '../../../components/EVJumbotron/CarDetailsTable/CarDetailsTable';

const EVJumbotron = ({ car, paymentDetails, cars, setUsedEv }) => {
 

  if (!car) return null;

  const handleClick = () => {
    window.location.href = '/incentives';
  };

  //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
  // It will remove any null value
  let imagesSrc =
    car && car['images']
      ? car.images.map((n) =>
          n.url_thumbnail == null ? [] : n.url_full == null ? [] : [n]
        )
      : [];
      

  //Flatten evImgs into a single array
  const images = [].concat.apply([], imagesSrc);
  let carImages = images.map((image) => {
    return {
      original: image.url_full,
      thumbnail: image.url_thumbnail,
      originalAlt: image.alt,
      thumbnailAlt: image.title,
      legalInfo: image.legal_info,
    };
  });
  carImages.shift();



  return (
    <>
      <section className="container-fluid" id="ev-jumbotron-title">
        <div className="container evContainer">
          <div className="row">
            <div className="col">
              <a className="backButton" href="/vehicles">
                <img src={vector} alt="back-button"></img>
                <FormattedMessage
                  id="ev.carDetails.view.back"
                  defaultMessage="ALL VEHICLES"
                  description="ALL VEHICLES"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h1 className='evPageTitle'>
                {setUsedEv ? (
                  <FormattedMessage
                    id="ev.usedCarTitle"
                    defaultMessage="Used {car}"
                    description="Used Car Title"
                    values={{
                      car: FormatCarName(car).toUpperCase(),
                    }}
                  />
                ) : (
                  FormatCarName(car).toUpperCase()
                )}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8">
              <div className="text-center offset-lg-1 col-lg-10 col-md-12">
                <VehicleImage image={images[0]} size="full" isEvPage />
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4">
              {!setUsedEv && <PricePanels car={car} paymentDetails={paymentDetails} />}
              {setUsedEv && <UsedVehiclePanels cars={cars} car={car} setUsedEv={setUsedEv} />}
              <br />
              <div className="containerPaymentButtons">
                  <button
                    className="btn btn-aps-secondary"
                    onClick={(_) => SmoothScroll('CostOfOwnership')}
                  >
                    <FormattedMessage
                      id="ev.jumbotron.totalCost"
                      defaultMessage="SEE TOTAL COST OF OWNERSHIP"
                      description="total cost"
                    />
                  </button>
                  
                  <button className="btn btn-aps"  onClick={handleClick}>
                    <FormattedMessage
                      id="ev.jumbotron.maximizeIncentives"
                      defaultMessage="SEE INCENTIVES"
                      description="maximize incentives"
                    />
                  </button>
                  
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carDetailContainer">
        <div className="container">
          <CarDetailsTable car={car} />
        </div>
      </section>
    </>
  );
};

export default EVJumbotron;

EVJumbotron.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object,
};
