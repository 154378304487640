export default {
  electricVehicles: 'Autos Eléctricos',
  usedElectricVehicles: 'Autos Eléctricos Usados',
  compareVehicles: 'Comparar Vehículos',
  incentives: 'Incentivos',
  chargingStations: 'Estaciones de Carga',
  homeChargers: 'Cargadores Caseros',
  dealers: 'Distribuidores',
  newVehicles: 'Autos Nuevos',
  electricians: 'Electricistas',
  homeChargingAdvisor: 'Consejero de Carga en el Hogar',

  newMexico: 'Nuevo Mexico',
  noResults: 'No se han encontrado resultados',
  localInventoryNavTitle: 'Inventario Local',
  localInventory: 'Inventario Local',

  'homeChargingPrograms.subheader':
    'Más del 80 % de la carga de vehículos eléctricos se realiza en casa. Estamos desarrollando soluciones para ahorrarle dinero y facilitar la carga de su EV en casa: envíenos un correo electrónico en {mailTo} lo mantendremos informado cuando haya nuevos programas disponibles en New Mexico',

  'disclaimer.electricRateDollarsKwH.newMexico':
    'La tarifa de electricidad que se muestra es un ejemplo de un conductor de EV en Nuevo México que carga durante el verano en el plan de tarifa residencial regular.',
  'disclaimer.electricRateDollarsKwH.colorado':
    'La tarifa de electricidad que se muestra es un ejemplo de un conductor de EV en Colorado que carga fuera de las horas pico en el plan de tarifas Hora del día - Toda la Casa.',
  'disclaimer.chargingMap':
    'Datos cortesía del Centro de Datos de Combustibles Alternativos. Es posible que el mapa no refleje la disponibilidad más reciente de las estaciones de carga.',

  electricity: 'Fuente',
  gasoline: 'Gasolina',
  total: 'Total',
  description: 'Descripción',
  detailedCalculations: 'detalles del cálculo',
  charging: 'Carga',
  emissions: 'Emisiones',
  source: 'Fuente',
  vehicles: 'Vehículos',
  make: 'Marca',
  model: 'Modelo',
  trim: 'Recortar',
  all: 'Todos',
  age: 'Años',
  zipcode: 'Código Postal',
  cheaperTo: 'mas barato',
  moreExpensive: 'más cara',

  electricVehiclesHeader: 'Autos Eléctricos',

  personalizeIncentives: 'Personalizar Incentivos',

  'vehicle.miles': 'Millas',
  'vehicle.trees': 'Árboles',
  'vehicle.view': 'Ver',
  'vehicle.more': 'más',
  'vehicle.fewer': 'menos',
  'vehicle.greater': 'más',

  'vehicle.plugInHybrid': 'Híbrido Eléctrico Enchufable',
  'vehicle.downPaymentMonth': 'durante {months} meses',
  'vehicle.downPayment': '+ {downPayment} pago inicial',
  'vehicle.allElectric': 'Eléctricos',
  'vehicle.gas': 'Gasolina',
  'vehicle.afterIncentives': 'Después de incentivos',
  'vehicle.msrp': 'MSRP',
  'vehicle.estimatedIncentives': 'Insentivos estimados',
  'vehicle.type': 'Tipo',
  'vehicle.fuelType': 'Tipo de combustible',
  'vehicle.batterySize': 'Tamaño de la batería',
  'vehicle.electricRange': 'Rango eléctrico',
  'vehicle.timeToChargeLvlTwo': 'Tiempo de carga - Nivel 2',
  'vehicle.milesPerThirtyFastCharge': 'Millas por 30 minutos de carga rápida',
  'vehicle.coTwoEmissions': 'Reducción de emisiones de CO2',
  'vehicle.phev': 'Electricidad y gasolina ',
  'vehicle.bev': 'Electricidad',
  'vehicle.age.one': '0-1 Años',
  'vehicle.age.two': '2-3 Años',
  'vehicle.age.four': '4+ Años',

  'vehicle.type.sedan': 'Sedán',
  'vehicle.type.hatchback': 'Cinco puertas',
  'vehicle.type.coupe': 'Coupé',
  'vehicle.type.crossover': 'Cruzado',
  'vehicle.type.minivan': 'Minivan',
  'vehicle.type.suv': 'SUV',
  'vehicle.type.wagon': 'Camión',
  'vehicle.type.truck': 'Vagón',
  'footer.allRights': 'Todos los derechos reservados.',
  'footer.poweredBy': 'Patrocinado por Zappyride',
  footerAdditional:
    'Este es un sitio web de terceros que pertenece, es controlado y administrado por ZappyRide, no por la Compañía de Servicios Públicos de Arizona (APS). ZappyRide puede compartir o proporcionar de otra manera el acceso a la información no personal identificable a APS en relación con el funcionamiento y la gestión del Sitio por parte de ZappyRide. La política de privacidad de APS regula la recolección, recepción y uso de dicha información en {link} y está disponible aquí.',

  yourGuide: 'Tu guía de vehículos eléctricos.',
  estimate:
    'Calcula y compara los costos, el ahorro, los incentivos y mucho más.',
  'homepage.findYour': 'Encuentra tu VE',

  'homepage.welcome': 'Colton Recharged',
  'homepage.welcomeSub':
    'Su guía para autos eléctricos, diseñada por Colton Electric Utility. Obtenga un estimado y compare costos, opciones de ahorro, incentivos y mucho más para VE.',

  'homepage.linkCard.browseElectricVehicles': 'VEHICULOS ELÉCTRICOS',
  'homepage.linkCard.discoverIncentives': 'DESCUBRA INCENTIVOS',
  'homepage.linkCard.locateChargingStations': 'ENCUENTRE ESTACIONES DE CARGA',
  'homepage.linkCard.homeChargers': 'CARGADORES PARA EL HOGAR',

  'homepage.vehicleCarousel.title':
    'Hay más vehículos eléctricos disponibles que nunca.',
  'homepage.chargingMap.title': 'RECARGA EN MOVIMIENTO, DONDE VAYAS',
  'homepage.vehicleCarousel.title.discover': 'Descubre el tuyo.',
  'homepage.vehicleCarousel.subTitle':
    'Compara el costo de tu selección con el de un vehículo de gasolina similar. Un coche eléctrico puede ahorrarte dinero porque puede ser menos costoso de poseer y mantener que un coche 100% de gasolina.',
  'homepage.vehicleCarousel.findEVButton': 'Encuentre su vehículo eléctrico',
  'homepage.incentives': 'Descubre los incentivos',
  'homepage.compare': 'Compara vehículos',
  'homepage.charging': 'Encuentra estaciones de carga',
  'homepage.browse': 'Descubre los vehículos eléctricos',
  'homepage.banner.title': 'TU GUÍA SOBRE EL SVE.',
  'homepage.banner.title.end':
    'Calcule y compare costos, ahorros, incentivos y más',

  'graph.assumptions': 'Supuestos',
  'graph.yourEV': 'VE seleccionado',
  'graph.similarGasVehicle': 'Vehículo comparable a gasolina ',
  'graph.graphValues': 'Valores Graficados',
  'graph.show': 'Mostrar',
  'graph.hide': 'Ocultar',
  'graph.moreExpensive': 'mas caro',
  'graph.cheaper': 'mas barato',
  'graph.toOwnOver': 'de tener por ',
  'graph.years': ' Años',
  'graph.year': ' Año',

  'graph.costOfOwnership.title': 'Costo de Propiedad',
  'graph.costOfOwnership.subTitle':
    'El {carName} es {style} {costDeltaText} {costDeltaTextEnding}',
  'graph.costOfOwnership.subTitleThreeCars':
    'El {carName} es el más barato de tener por {yearsOfOwnership} años',
  'graph.costOfOwnership.descriptionRow.vehicle': 'Vehículo',
  'graph.costOfOwnership.descriptionRow.maintenance': 'Mantenimiento',
  'graph.costOfOwnership.descriptionRow.insurance': 'Seguro',
  'graph.costOfOwnership.descriptionRow.electricity': 'Electricidad',
  'graph.costOfOwnership.descriptionRow.gasoline': 'Gasolina',
  'graph.costOfOwnership.totalRow': 'Total',
  'graph.costOfOwnership.description': 'Descripción',

  'graph.costOfOwnership.chart.vehicle': 'Incentivos para vehículo, Reventa.',
  'graph.costOfOwnership.chart.electricity': 'Electricidad',
  'graph.costOfOwnership.chart.gasoline': 'Gasolina',
  'graph.costOfOwnership.chart.maintenance': 'Mantenimiento',
  'graph.costOfOwnership.chart.insurance': 'Seguro',

  'graph.title.costAnalysis': 'Análisis de Costos',
  'graph.title.speedOfCharge': 'Velocidad de Carga',
  'graph.title.electricRange': 'Rango Eléctrico',
  'graph.monthlyCostToFill.title': 'Costo de Llenar el Tanque Mensualmente',

  'graph.speedOfCharge.levelTwoChargingSpeed': 'Velocidad de carga Nivel 2',
  'graph.speedOfCharge.fastChargingSpeed': 'Velocidad de carga rápida',
  'graph.speedOfCharge.mphLevelTwoCharger':
    'millas por hora al usar un cargador nivel 2 ',
  'graph.speedOfCharge.thirtyFastCharge':
    'millas por cada 30 minutos de carga rápida',
  'graph.speedOfCharge.subTitle':
    'El {car} tendrá {number} {costDeltaText} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerThreeCars':
    'será el que gane más rango de carga tras una hora de uso de un cargador nivel 2',
  'graph.speedOfCharge.thirtyFastChargeThreeCars':
    'será el que gane más rango de carga después de 30 minutos de carga rápida',
  'graph.speedOfCharge.subTitleThreeCars': 'El {car} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerTitle':
    'Porcentaje de carga por hora en Nivel 2',
  'graph.speedOfCharge.fastChargerTitle':
    'Cantidad de millas por 30 minutos de carga rápida.',
  'graph.electricRange.subTitleThreeCars':
    'El {car} tiene el rango eléctrico más amplio de todos',
  'graph.electricRange.subTitle':
    'El rango eléctrico del {car} tiene {number} millas { costDeltaText} ',
  'graph.electricRange.gasolineRange': 'Rango de gasolina',
  'graph.electricRange.assumption': 'Rango basado en estimaciones de la EPA',
  'graph.monthlyCostToFill.subTitle':
    'Mesualmenta, el {carName} es {style} {costDeltaText} de tanquear',
  'graph.monthlyCostToFill.subTitleThreeCars':
    'Mensualmente, el {carName} cuesta $100 más para repostar.',

  'evs.incentivesScoreOptions': 'Filtros de elegibilidad',
  'evs.incentivesFilterHeader': 'Filtros de elegibilidad',

  'evs.electricFuelFilter': 'Eléctricos',
  'evs.hybridFuelFilter': 'Híbrido',

  'inventory-filter.filter-vehicles': 'Filtros',
  'inventory-card.vehicle-details': 'Ver detalles',
  'evs.inventorythirdParty':
    '*Este sitio web contiene enlaces a sitios web externos de terceros. Arizona Public Service no respalda ni acepta ninguna responsabilidad por el contenido o los productos vendidos en sitios web de terceros. Arizona Public Service no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web externos de terceros. Al hacer clic en los enlaces, usted libera expresamente a Arizona Public Service de toda responsabilidad que surja de su uso del sitio web externo de terceros, o del contenido de cualquier sitio web externo de terceros.',
  toolGeneralDisclaimer:
    'Este es un sitio web de terceros, ofrecido, controlado y administrado por ZappyRide, no Arizona Public Service Company (APS). ZappyRide puede compartir o proporcionar acceso a información de identificación no personal a APS en relación con la operación y la gestión del sitio de ZappyRide. La Política de privacidad de APS rige su recopilación, recibo y uso de dicha información en APS.com y está disponible aquí.',
  vehicleType: 'Select all vehicle body types you are interested in.',
  homeChargerAvailability:
    'Cargar tu vehiculo electrico (VE) en casa es más conveniente y accesible. Si no puedes, estimamos los costos de carga pública. Nota: Los cargadores de nivel 1 requieren 120V estándar, o 240V pare los caradores nivel 2.',
  householdIncome:
    'Combina los ingresos de todos los miembros de tu hogar. Utilizamos esta información para determinar tu elegibilidad de incentivos.',
  householdSize:
    'Incluye a todos los miembros de tu hogar (cónyuge, dependientes, etc.). Utilizamos esta información para determinar la elegibilidad de incentivos.',
  taxFilingStatus:
    'Utilizamos esta información para determinar la elegibilidad de incentivos.',
  retireAVehicle:
    'Se puede requerir retirar un vehículo de gas antiguo o existente para ciertos incentivos.',
  timeToCharge:
    'Asume carga desde batería vacía con un cargador de nivel medio 2 promedio (7.7 kW). Nota: Los cargadores de nivel 2 requieren una salida de 240 V. Los tiempos de carga de nivel 1 son mucho más largos.',
  batterySize:
    'Un kilovatio-hora es suficiente energía para conducir de 1 a 4 millas, dependiendo del vehículo. Como referencia, una batería de laptop típica puede contener menos de 0.1 kWh.',
  electricMilesDriven:
    'Estima qué porcentaje de tus millas de conducción será completamente eléctrico. Si recargas diariamente y conduces menos por día que el rango totalmente eléctrico, tu porcentaje será cerca del 100%. Para la mayoría de la conducción típica más allá del rango totalmente eléctrico, el 50% es una suposición segura.',
  graphDisclaimerPrice:
    'El precio de compra de un VE puede ser más alto en comparación con un vehículo de motor de combustión interna similar. Sin embargo, algunos de los costos iniciales más altos asociados con los VE pueden compensarse con incentivos del vehículo, un menor costo total de propiedad y un valor de reventa más fuerte.',
  graphDisclaimerElectricity:
    'El costo de electricidad puede ser menos que el costo del combustible, a la misma distancia recorrida.',
  graphDisclaimerGas:
    'Los costos de combustible de gasolina suelen ser más que los costos de electricidad.',
  graphDisclaimerMaintenance:
    'Los costos de mantenimiento son generalmente más bajos para los VE debido a que tienen menos piezas móviles.',
  graphDisclaimerInsurance:
    'Los costos de seguro de los vehiculos electricos (VE) pueden ser mas altos que los costos de seguro de un vehiculo típico, debido al mayor precio de compra de los VE. Los costos de seguro más altos pueden compensarse con los ahorros de combustible y mantenimiento.',
  graphDisclaimerCostAnalysis:
    'Las comparaciones reflejan ahorros aproximados y no están garantizadas. Los ahorros reales variarán según el uso individual y las condiciones del mercado. APS no ofrece representaciones ni garantías de ningún tipo, ya sea expresas o implícitas, con respecto a la precisión de la información contenida en este documento.',
  costAnalysisDisclaimer:
    'Las comparaciones reflejan ahorros aproximados y no están garantizadas. Los ahorros reales variarán según el uso individual y las condiciones del mercado. APS no ofrece representaciones ni garantías de ningún tipo, ya sea expresas o implícitas, con respecto a la precisión de la información contenida en este documento.',
  rangeMap:
    'Según lo anunciado por el fabricante. El rango real puede diferir, dependiendo de los factores ambientales, el comportamiento de conducción, el mantenimiento del vehículo y la edad de la batería.',

  'homepage.incentives.title':
    'Descubra incentivos y créditos fiscales para VE ',
  'homepage.incentives.subTitle':
    'Descubra que tanto puede ahorrar con un VE, ya sea por compra o alquiler. Los incentivos son personalizados según su lugar de residencia.',
  'homepage.incentives.exporeIncentives': 'DESCUBRA INCENTIVOS',

  'compareVehicles.subTitle':
    'Elige al menos dos vehículos para comenzar la comparación.',
  'compareVehicles.selectFuel': 'Seleccione el Tipo de Combustible ',
  'compareVehicles.selectMake': 'Seleccione la marca',
  'compareVehicles.selectModel': 'Seleccione el modelo',
  'compareVehicles.pickTwo':
    'Elíja al menos 2 vehículos para hacer la comparación',
  'compareVehicles.disclaimer':
    'No todas las marcas / modelos están disponibles.',

  'evs.welcomeSub': 'Encuentra el adecuado para ti.',
  'evs.buttonMatchScoreAndFilters': 'Filtros',
  'evs.matchScoreAndFilters': 'Filtros',
  'evs.matchScoreOptions': 'Filtros',
  'evs.roundTripCommute': 'Viaje de ida y vuelta',
  'evs.budgetAfterIncentives': 'Presupuesto después de incentivos',
  'evs.minSeats': 'Cantidad mínima de asientos',
  'evs.seats': 'Asientos',
  'evs.homeChargingAvailability': 'Disponibilidad de carga en el hogar',
  'evs.homeChargingAvailabilityTooltip':
    'Más autos eléctricos serán mejores para ti si tienes la posibilidad de cargarlos rapidamente en casa.',
  'evs.noCharging': 'Sin carga',
  'evs.levelOne': 'Nivel 1',
  'evs.levelTwo': 'Nivel 2',
  'evs.helpMeChoose': 'Ayúdame a elegir',
  'evs.chargingAvailability': 'Disponibilidad de carga',
  'evs.disclaimer':
    'Los vehículos mostrados pueden no reflejar la disponibilidad real. {company} no respalda ni recomienda ningún vehículo o fabricante de automóviles específico.',
  'evs.thirdParty':
    '*Este sitio web contiene enlaces a sitios web externos de terceros. Arizona Public Service no respalda ni acepta ninguna responsabilidad por el contenido o los productos vendidos en sitios web de terceros. Arizona Public Service no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web externos de terceros. Al hacer clic en los enlaces, usted libera expresamente a Arizona Public Service de toda responsabilidad que surja de su uso del sitio web externo de terceros, o del contenido de cualquier sitio web externo de terceros.',
  'evsCatalog.title': 'DESCUBRE NUEVOS VEHÍCULOS ELÉCTRICOS',
  'evs.availableNow': "DISPONIBLE AHORA",
  'evs.availableTooltip': "Los vehículos disponibles ahora se pueden comprar para entrega, en lugar de simplemente estar abiertos para pedidos anticipados. El inventario real puede variar localmente.",

  'chargingWizard.carOvernight': '¿En dónde parquear tu Auto en la noche?',
  'chargingWizard.parkingGarage': 'Garaje privado o estacionamiento',
  'chargingWizard.onTheStreet': 'En la calle',
  'chargingWizard.publicParking': 'Estacionamiento público',
  'chargingWizard.electricianInstall':
    '¿Puede un electricista instalarme una estación de carga?',
  'chargingWizard.overnightParking':
    '¿Podrías estacionar tu auto en un lugar que cuenta con estación de carga?',
  'chargingWizard.powerOutlet':
    '¿Existe algún tomacorriente cerca al lugar en donde estacionas tu auto?',
  'chargingWizard.chargingAvailability': ' Disponibilidad de carga',
  'chargingWizard.noChargingAvailability': 'No disponibilidad de carga',
  'chargingWizard.youWouldHave': 'Tendrás {resultText} ',
  'chargingWizard.useThisValue': 'Usar este valor',

  evfilter: 'Filtro',
  'evfilter.fuel': 'Combustible',
  'evfilter.fuelTooltip':
    'Los vehículos eléctricos solo usan electricidad. Los vehículos híbridos pueden funcionar con electricidad y gasolina ',
  'evfilter.type': 'Tipo',

  egbBringCharger: 'Usaré mi propio cargador de nivel 2',

  yes: 'Si',
  no: 'No',

  'evCard.electricRange': 'Rango eléctrico',
  'evCard.totalRange': 'Rango total',
  'evCard.matchScore': 'Puntaje personalizado',
  'evCard.seeDetails': 'Detalles',
  'evCard.seeElectricVehicle': 'Ver Vehículo Eléctrico',

  'evSort.title': 'Ordenar por',
  'evSort.matchScore': 'Puntaje personalizado',
  'evSort.electricRange': 'Rango eléctrico',
  'evSort.priceLtH': 'Precio: Menor a mayor',
  'evSort.priceHtL': 'Precio: Mayor a menor',
  'evSort.alphabetical': 'Orden alfabético',
  'incentiveSort.largest': 'Primero el más grande',
  'incentiveSort.aToZ': 'Alfabético: de la A a la Z',
  'incentiveSort.zToA': 'Alfabético: Z a A',
  'evSort.alphabeticaldesc': 'Alfabético: de la A a la Z',
  'evSort.alphabeticalasc': 'Alfabético: Z a A',
  'evSort.chargeasc': 'Carga más rápida',
  'incentives.selectMake': 'Seleccionar Marca',
  'incentives.selectModel': 'Seleccionar Modelo',
  'incentives.selectTrim': 'Seleccionar Recortar',

  'pricePanels.cash': 'Efectivo',
  'pricePanels.loan': 'Crédito',
  'pricePanels.lease': 'Arrendamiento',
  'pricePanels.loanPayment': 'Pago del préstamo',
  'pricePanels.downPayment': 'Cuota inicial',
  'pricePanels.leasePayment': 'Pago de arriendo',
  'pricePanels.perMonth': ' / Mes',
  'pricePanels.downPaymentDescription': '10% de MSRP más impuestos',
  'pricePanels.firstLeasePayment': 'Primera cuota de la renta',
  'pricePanels.incentivesForLease': 'Incentivos para rentar',
  'pricePanels.seeAllIncentives': 'Ver todos los incentivos',
  'pricePanels.seeAllCosts': 'Ver todos los costos',

  'ev.jumbotron.viewAllCars': 'Ver todos los autos',
  'ev.jumbotron.maximizeIncentives': 'VER INCENTIVOS',
  'ev.jumbotron.totalCost': 'VER COSTO TOTAL',
  'ev.jumbotron.compare': 'COMPARAR CON OTROS VEHÍCULOS',
  'ev.jumbotron.localInventory': 'VER INVENTARIO LOCAL',

  'ev.carDetails.fastChargingTooltip': 'Velocidad de carga rápida con DC:',
  'ev.carDetails.levelTwoChargerSpeedTooltip':
    'Asume carga desde batería vacía con un cargador de nivel medio 2 promedio (7.7 kW). Nota: Los cargadores de nivel 2 requieren una salida de 240 V. Los tiempos de carga de nivel 1 son mucho más largos.',
  'ev.carDetails.batterySizeTooltip':
    'Un Kilovatio por hora (kWh) provee suficiente energía para conducir de 1 a 4 millas dependiendo del vehículo. Una batería común para computadora puede contener menos de 1 kWh',
  'ev.carDetails.treesPlanted': 'Árboles plantados',
  'ev.carDetails.gasolineSaved': 'Gasolina ahorrada',
  'ev.carDetails.compareText':
    'Seleccionamos el {gasVehicle} por su proximidad con el {vehicle} con base en la marca, tamaño y precio.',
  'ev.cardetails.calc.dcRate': 'Cargador Rápido (DCFC)',
  'ev.cardetails.calc.EPA': 'Calificación de eficiencia EPA',
  'ev.cardetails.calc.chargingRate': 'Velocidad de carga',
  'ev.cardetails.calc.vehicleBatteryCapacity': 'Capacidad de la batería',
  'ev.cardetails.calc.maxACIntake': 'Capacidad maxima de AC',
  'ev.cardetails.calc.lvlTwoChargingRate': 'Velocidad de carga Nivel 2',
  'ev.cardetails.calc.minOflvl2AC':
    'Nivel mínimo de carga y capacidad máxima de absorcion del AC',
  'ev.cardetails.calc.lvl2FullCharge': 'Tiempo de carga total en Nivel 2',
  'ev.cardetails.calc.milesDrivenPerYr': 'Millas conducidas al año',
  'ev.cardetails.calc.userInput': 'Introducción de datos por el usuario',
  'ev.cardetails.calc.milesPerGallon': 'Millas por Galón (mpg)',
  'ev.cardetails.calc.mpg': ' millas/gal',
  'ev.cardetails.calc.portionElectric': 'Porción eléctrica usada',
  'ev.cardetails.calc.portionElectricSource':
    'Introducción de datos por el usuario. Para un vehículo híbrido, estima la porción de millas conducidas usando batería eléctrica en vez de gasolina.',
  'ev.cardetails.calc.gallonsUsed': 'Galones Usados',
  'ev.cardetails.calc.gallonsUsedFormula':
    'Millas manejadas * (1 - Porción eléctrica usada) / MPG',
  'ev.cardetails.calc.gallonsSaved': 'Galones Ahorrados',
  'ev.cardetails.calc.gallonsSavedFormula':
    'Galones usados por un vehiculo a gasolina comparable - Galones usados por VE',
  'ev.cardetails.calc.electricEmissions':
    'Emisiones por consumo eléctrico en Lbs',
  'ev.cardetails.calc.electricEmissionsLbsMwh':
    'Emisiones de CO2 por consumo eléctrico en lbs/MWh',
  'ev.cardetails.calc.EIA':
    'Administración de información energética de Estados Unidos',
  'ev.cardetails.calc.gasolineEmissionsYr':
    'Emisiones por consumo de gasolina en lbs de CO2/yr',
  'ev.cardetails.calc.gasolineEmissions':
    'Emisiones de CO2 por consumo de gasolina en lbs/gal',
  'ev.cardetails.calc.emissionsReudctions': 'Reducción de emisiones',
  'ev.cardetails.calc.lbsCo2PerYr': ' lbs de CO2 al año',
  'ev.cardetails.calc.gasolineEmissionsLbs':
    'Emisiones por consumo de gasolina en lbs',
  'ev.cardetails.calc.arborDayFoundation': 'Fundación Arbor Day',
  'ev.cardetails.calc.emissionsPerTree':
    'Emisiones capturadas por árbol por año',
  'ev.cardetails.calc.electricityEmissions':
    'Emisiones de CO2 por consumo eléctrico en lbs/MWh',
  'ev.usedCarTitle': '{car} Usado',
  'ev.cardetails.electricRange':
    'To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.',
  'ev.carDetails.usedBatterySizeTooltip':
    'One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.',

  'ev.purchaseMethod': 'Forma de pago',
  'ev.purchaseMethodToolTip':
    'En modo renta, usted tiene que devolver el auto al final del periodo de arrendamiento. En el caso de financiación con un crédito, usted se queda con el auto al terminar de pagar el crédito.',
  'ev.cash': 'Efectivo',
  'ev.loan': 'Crédito',
  'ev.lease': 'Renta',
  'ev.milesDrivenAnnually': 'Millas conducidas por año',
  'ev.electricPortion': 'Millas eléctricas recorridas',
  'ev.electricPortionTooltip':
    'A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.',
  'ev.yearsOwnership': 'Compra/renta al año',
  'ev.interestRate': 'Tasa de interés',
  'ev.electricityRateCalc':
    'El precio por kilovatio-hora proporcionado es la tarifa eléctrica residencial promedio anual para Arizona según la Administración de Información de Energía de EE. UU (EIA). El costo real de la carga en el hogar depende del plan de tarifas eléctricas de su hogar, así como también de cuándo y con qué frecuencia carga.',

  'assumption.salesTax': 'Impuesto a las ventas',
  'assumption.gasolinePrice': 'Precio de gasolina',
  'assumption.downPayment': 'Cuota inicial',
  'assumption.electricityRate': 'Rango eléctrico',
  'assumption.downPaymentValue': '10% de MSRP más impuestos',

  'ev.compareGasVehicle':
    'Compare el {car} con un vehículo similar a gasolina, el {gasCar}.',
  'ev.compareOtherVehicles': 'COMPRE OTHER VEHICLES',
  'ev.incentives.title':
    'Hasta {savings} en créditos fiscales y reembolsos están disponibles {lineBreak} para el {car}.',
  'ev.reviews': 'Reseñas en la web',

  'chargingMap.updateZipcode': 'Actualizar Código Postal',
  'chargingMap.errorZipcode':
    'Error: {workingZipcode} no es un código postal válido',
  'chargingMap.showFullListOfStations': 'lista completa de estaciones',
  'chargingMap.public': 'Estaciones Públicas',
  'chargingMap.businessGovernment': 'Instaladas por empresas o el gobierno',
  'chargingMap.highPowerStations': 'Estaciones de Alta Potencia',
  'chargingMap.DCChargers': 'Carga rápida CC o súper-cargadores',
  'chargingMap.otherSttations': 'Otros Tipos de Estaciones',
  'chargingMap.privateStations': 'Estaciones rivadas',
  'chargingMap.searchDealers': 'Buscar Distribuidores Calificados',
  'chargingMap.otherStations': 'Otros tipos de estaciones',
  'chargingMap.searchCharging': 'ACTUALIZAR CÓDIGO POSTAL',

  'dealers.brands': 'Marcas',
  'dealers.allBrands': 'Todos las Marcas',
  'dealers.allDealers': 'Todos los Distribuidores',
  'dealers.contact': 'CONTACTO',
  'dealers.website': 'PAGINA WEB',
  'dealers.findDealers': 'Encontrar Distribuidores',

  'incentives.title': 'Incentivos para Vehículos Eléctricos',
  'incentives.subTitle':
    'Usted puede ser elegible para un rango de incentivos, incluyendo reembolsos, créditos fiscales y otros beneficios. Los incentivos son personalizados según su lugar de residencia.',
  'incentives.single': 'Soltero',
  'incentives.married': 'Casado',
  'incentives.headOfHousehold': 'Cabeza de hogar',
  'incentives.vehicleType': 'Tipo de vehículo',
  'incentives.vehicleTypeTooltip':
    'Ciertos incentivos dependen de qué carro compres.',
  'incentives.tradeIn': 'Intercambio',
  'incentives.income': 'Estado de ingresos e impuestos',
  'incentives.incomeTooltip':
    'La elegiblidad para incentivos y la cantidad que puedes recibir depende normalmente de tu nivel de ingresos. Clientes con niveles de ingresos menores son usualmente elegibles para reembolsos mayores.',
  'incentives.clunkerNone':
    'Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.',
  'incentives.clunkertoolTip':
    'Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.',
  'incentives.clunkertoolTipContd':
    ' Están disponibles en tu región. Los requisitos para elegibilidad varían por incentivo, por favor revise los detalles del programa.',
  'incentives.householdSize': 'Tamaño del Hogar',
  'incentives.householdIncome': 'Ingresos del Hogar',
  'incentives.planClunker': '¿Estás planeando intercambiar tu carro viejo?',
  'incentives.location': 'Ubicación',
  'incentives.locationTooltip':
    'Muchos incentivos dependen del lugar en el que vives. Por favor ingresa tu código zip para ayudarte a determinar cuáles incentivos están disponibles en tu lugar de residencia.',
  'incentives.taxFilingStatus': 'Estado Civil para Efectos de la Declaración',
  'incentives.whichVehicle': '¿Qué vehículo planeas comprar?',
  'incentives.incentiveEligibility': 'Actualizar Elegibilidad para Incentivos',
  'incentives.qualify':
    'Puede calificar para recibir hasta {sum} en ahorros provenientes de reembolsos y créditos fiscales para vehículos.',
  'incentives.disclaimer':
    'La elegibilidad y los montos de los incentivos no constituyen asesoramiento fiscal o legal. Consulte al otorgante del incentivo y/o a su profesional legal o de impuestos para determinar la elegibilidad, la cantidad específica de incentivos o reembolsos disponibles.',

  'homeChargers.subText':
    'Seleccione el vehículo que le interesa para ver los detalles de tiempo de carga total de la bateria.',
  'homeChargers.wallMounted': 'Montaje en pared',
  'homeChargers.portable': 'Portátil',
  'homeChargers.price': 'Precio',
  'homeChargers.cordLength': 'Longitud del cable',
  'homeChargers.wifi': 'Conectividad Wifi',
  'homeChargers.socket': 'enchufe',
  'homeChargers.socketTooltip':
    'Cada modelo de cargador tiene un patrón de enchufe diferente. Estos tipos de patrón corresponden a la forma en la que las puntas encajan en la toma de corriente de la pared. Si no está seguro de cuál es el mejor para su hogar/garaje, consulte con un electricista calificado..',
  'homeChargers.cordLengthLtH': 'Longitud del cable: Menor a mayor',
  'homeChargers.cordLengthHtL': 'Longitud del cable: Mayor a menor',
  'homeChargersCard.buy': 'Detalles y Compra',
  'homeChargersCard.beforeIncentives': 'Antes de incentivos',
  'homeChargersCard.socket': 'Enchufe {socket}',
  'homeChargers.cable': 'Cable de {length} ft.',
  'homeChargers.hardwired': 'Cableado Enchufe',
  'homeChargers.toFullCharge': 'para carga completa',

  'electricians.residential': 'Residencial',
  'electricians.commercial': 'Comercial',
  'electricians.industrial': 'Industrial',
  'electricians.type': 'Tipo de Electricista',
  'electricians.searchElectricians': 'Buscar Electricistas Calificados',

  'tabbed-maps-dealer-catalog-tab': 'Distribuidores',
  'tabbed-maps-all-stations-tab': 'Estaciones de Carga',
  'tabbed-maps-electricians-tab': 'Electricistas',
  'tabbed-maps-route-tab': 'Buscar una Ruta',
  'tabbed-maps-range-tab': 'Mapa de Rango',

  viewTravelRadius: 'Ver radio de viaje',
  radiusSpecification: 'millas de rango total desde nivel completo',

  tcoSavingsHeading:
    'El {car1} no es actualmente más barato de poseer que su vehículo existente',

  here: 'aquí',
  any: 'cualquier',
  new: 'Nuevo',
  preOwned: 'Seminuevo',
  preOwnedElectricVehicles: 'Vehículos Eléctricos Usados',
  'usedEVCard.yearRange': 'Rango de Años',
  'usedEVCard.electricRangeAverage': 'Promedio de Rango Eléctrico',
  'header.chargingStations': 'Carga Pública',
  'header.subHeader':
    'Es posible que se disponga de créditos del impuesto federal sobre la renta. Las cantidades reales varían. Consulta a tu asesor fiscal. Mientras tanto, ¡feliz navegación!',
  home: 'Hogar',
  feet: 'pies',
  month: 'mes',
  electricVehicle: 'Auto Eléctrico',
  maximum: 'máxima',
  details: 'Detalles',
  'homepage.vehicleCarousel.compareVehiclesButton':
    'ENCUENTRA TU VEHÍCULO ELÉCTRICO',
  'evs.newButton': 'NUEVO',
  'evs.usedButton': 'USADO',
  'ev.carDetails.view.back': 'TODOS LOS VEHICULOS',
  'ev.electricityRate': 'TARIFA DE ELECTRICIDAD ($/kWh)',
  'graph.costToBreakdown.subTitleCheaper':
    'El {car1} se vuelve más barato después de {time}',
  'evs.flipBack': 'Voltear hacia atrás',
  'evs.moreInfo': 'Más información',
  'evs.flipDesc': 'VOLTEAR PARA VER LA DESCRIPCIÓN',
  'map.input.highPower': 'Solo estaciones de alta energía',
  'map.input.startLocation': 'Ubicación de inicio',
  'map.input.endLocation': 'Ubicación FINAL',
  'map.input.includeCharge': 'Incluir estaciones de carga dentro',
  'map.input.mapRoute': 'RUTA DEL MAPA',

  'footer.links.title': 'Departamento de Energía Comunitaria',
  'footer.links.contact': 'Contáctenos',
  'footer.links.join': 'Únete a Nuestro Equipo',
  'footer.links.media': 'Medios',
  'footer.links.opt': 'Optar por no Participar',
  'footer.links.customer': 'Confidencialidad del Cliente',
  'footer.links.policy': 'Política',
  'footer.links.sanJose': 'Energía de San José',
  'footer.bottom.rights': ' Todos los Derechos Reservados',
  'footer.bottom.provide1': 'Soluciones EV proporcionadas por',
  'footer.bottom.provide2': 'un negocio de J.D. Power',
  'footer.bottom.text':
    'Las cifras mostradas en esta página son estimaciones basadas en datos proporcionados por terceros. Este material se proporciona solo para fines informativos y no debe ser utilizado para asesoramiento fiscal, legal o financiero. Ni J.D. Power® ni San José Clean Energy proporcionan asesoramiento fiscal, legal o financiero. Debe verificar de manera independiente las estimaciones proporcionadas aquí antes de participar en cualquier transacción.',

  'page.chargingMap.title': 'ESTACIONES DE CARGA',
  'homepage.chargingMap.subtitle':
    'Con nuevas estaciones que se encienden todos los días, es más fácil que nunca conducir lejos y recargar. Ingresa tu ubicación para ver todas las estaciones de carga cerca de ti.',

  'travelRoute.OneMile': 'Dentro de 1 milla',
  'travelRoute.TwoMiles': 'Dentro de 2 millas',
  'travelRoute.FiveMiles': 'Dentro de 5 millas',

  'chargingMap.routeMapCopy':
    'Los rangos de kilometraje se basan en estimaciones proporcionadas por los fabricantes de vehículos eléctricos. Su alcance puede variar según el clima, la región, el tráfico, el modelo de automóvil y las condiciones del automóvil.',
};

