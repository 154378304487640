import React from 'react';
import PropTypes from 'prop-types';

import SlideElectricMilesPortionForPhev from '../InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev';
import SlideInterestRateAsBasisPoints from '../InputComponents/SlideInterestRateAsBasisPoints/SlideInterestRateAsBasisPoints';
import SlideMilesDrivenAnnually from '../InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually';
import SlideMonthsOfOwnership from '../InputComponents/SlideMonthsOfOwnership/SlideMonthsOfOwnership';
import SelectPurchaseMethod from '../InputComponents/SelectPurchaseMethod/SelectPurchaseMethod';
import SlideGasolinePrice from '../InputComponents/SlideGasolinePrice/SlideGasolinePrice';
import SlideSalesTax from '../InputComponents/SlideSalesTax/SlideSalesTax';
import SlideDownPayment from '../InputComponents/SlideDownPayment/SlideDownPayment';
import { FormattedMessage } from 'react-intl';
import InputElectricityRate from '../InputComponents/InputElectricityRate/InputElectricityRate';
import './CostOfOwnershipOptions.scss'

const CostOfOwnershipOptions = ({ cars }) => {
  if (!cars || cars.length === 0) return null;


  return (
    <>
      <div className="input-well">
        <SelectPurchaseMethod />
        <SlideMilesDrivenAnnually />
        <SlideElectricMilesPortionForPhev />

        <SlideMonthsOfOwnership />
        <SlideInterestRateAsBasisPoints />
        <SlideSalesTax />

        <SlideDownPayment />
        <SlideGasolinePrice />
        <InputElectricityRate />
      </div>
      <p className="selected-subText">
        <FormattedMessage
          id="ev.carDetails.endorseText"
          defaultMessage="We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability."
          description="Compare Text"
        />
      </p>
    </>
  );
};

CostOfOwnershipOptions.propTypes = {
  car: PropTypes.object,
};
export default CostOfOwnershipOptions;
