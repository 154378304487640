import React, { useState } from 'react';
import arrow from '../../assets/images/icons/arrow_forward.svg'
import IncentiveCard from '../../../components/IncentiveCard/IncentiveCard';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import './IncentivesCarousel.scss'
import useWindowSize from '../../../hooks/useWindowSize'

const IncentivesCarousel = ({ incentives }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { width } = useWindowSize()
    
    const filteredIncentives = incentives.filter(incentive => incentive?.evaluation?.eligibility !== 'ineligible').slice(0,8)
    const cardCount = width > 1024 ? 4 : 1 
   
    const nextVehicle = () => {
        setCurrentIndex((prevIndex) => {
        if (prevIndex + 4 < filteredIncentives.length) { 
            return prevIndex + 1;
        }
        return prevIndex;
        });
    };

    const prevVehicle = () => {
        setCurrentIndex((prevIndex) => {
        if (prevIndex > 0) { 
            return prevIndex - 1;
        }
        return prevIndex;
        });
    };


    return (
        <div className='carousel-container'>
            <button onClick={prevVehicle} className={`arrow-container left ${(currentIndex > 0) && 'enabled'}`}>
                <img src={arrow} alt='arrow left' />
            </button>

            <div className="incentive-list">
                {filteredIncentives ? 
                    
                    filteredIncentives
                    .slice(currentIndex, (currentIndex + cardCount))
                    .map((incentive, index) => {
                        return (
                            <IncentiveCard
                                incentive={incentive}
                                key={index}
                            />
                    )})
                    : <LoadingSpinner />
                }
            </div>

            <button onClick={nextVehicle} className={`arrow-container ${(currentIndex + 4 < filteredIncentives.length) && 'enabled'}`}>
                <img src={arrow} alt='arrow right' />
            </button>
        </div>
    )
}

export default IncentivesCarousel;