import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import IncentiveCard from '../IncentiveCard/IncentiveCard';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import sortIncentivesCatalog from '../../functions/incentives/Sort/sortIncentivesCatalog';
import UserPrefsContext from '../../context/UserPrefs/UserPrefsContext';
import IncentiveSortControls from '../../client_customizations/components/IncentiveSortControls/IncentiveSortControls';
import IncentiveSaves from '../../client_customizations/components/IncentiveSaves/IncentiveSaves';
import ModalComponent from '../ModalUIComponent/ModalUIComponent';
import { useIntl } from 'react-intl';


const IncentiveCatalog = ({ incentives, renderOptions, renderVehicleOptions }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  if (!incentives) return <LoadingSpinner />;

  const sortedIncentives = sortIncentivesCatalog(
    incentives,
    userPrefs.get('incentiveSortType'),
    userPrefs.get('incentiveSortDirection')
  );

  const handleClick = () => {
    document.getElementById('modal-fire-button').click();
  }
  
  return (
    <div className="homepage-incentives-list">
      <div className="homepage-incentives-sort d-none d-lg-block position-relative">
        <IncentiveSaves incentives={sortedIncentives} />
        <IncentiveSortControls />
      </div>
      <div className="col-sm-12 d-block d-lg-none text-center">
        <div className="row ">
          <div className="col">
            <IncentiveSortControls />
          </div>
        </div>
        <ModalComponent
          buttonText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "evs.buttonMatchScoreAndFilters",
                  defaultMessage: "OPEN FILTERS",
                })
              : "OPEN FILTERS"
          }
          titleText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "evs.matchScoreAndFilters",
                  defaultMessage: "Refine Match Score and Filters",
                })
              : "Refine Match Score and Filters"
          }
          isFullScreen
        >
          <>
            {renderOptions}
            {renderVehicleOptions}
            <div >
              <button 
                className='apply-button-filter-modal'
                onClick={handleClick}  
                type="button"
              >
                APPLY FILTERS
              </button>
            </div>
          </>
        </ModalComponent>
        <div className="row">
          <div className="col">
            <IncentiveSaves incentives={sortedIncentives} />
          </div>
        </div>
        <br />
      </div>
      <div className="homepage-incentives-cards">
        {sortedIncentives.map((incentive, index) => (
          <IncentiveCard
            displayNone={
              incentive.grantor_type === 'Power Supplier'
                ? incentive.grantor !== 'APS'
                : false
            }
            incentive={incentive}
            key={index}
            isIncentivePage
          />
        ))}
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool,
};
