import React from "react";
import PropTypes from "prop-types";

// import IncentivePreferences from "../IncentivePreferences/IncentivePreferences"

import SlideMilesDrivenDaily from "../../../components/InputComponents/SlideMilesDrivenDaily/SlideMilesDrivenDaily";
import SlideMaxBudget from "../../../components/InputComponents/SlideMaxBudget/SlideMaxBudget";
import SlideMinSeats from "../../../components/InputComponents/SlideMinSeats/SlideMinSeats";

import { FormattedMessage } from "react-intl";
import EVFilterControls from "../EVFilterControls/EVFilterControls";
import EVFilterType from "./EVFilterType";
import EVFilterAvailableNow from "./EVFilterAvailableNow";

const MatchScoreOptions = ({ electricVehicles, location }) => {
  const handleClick = () => {
    document.getElementById('modal-fire-button').click();
  }

  return (
    <>
      <div className="input-well MatchScoreOptions margin-top">
        <p className="h3 bold extra-class d-none d-lg-block">
          <FormattedMessage
            id="evs.matchScoreOptions"
            defaultMessage="Refine Match Score"
            description="Refine Match Score"
          />
        </p>

        <EVFilterControls vehicles={electricVehicles} />

        <SlideMaxBudget />
        {/* <IncentivePreferences
          electricVehicles={electricVehicles}
          location={location}
          titleText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "personalizeIncentives",
                  defaultMessage: "Personalize Incentives",
                })
              : "Personalize Incentives"
          }
          btnText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "personalizeIncentives",
                  defaultMessage: "Personalize Incentives",
                })
              : "Personalize Incentives"
          }
          btnSubtle
        /> */}

        <SlideMilesDrivenDaily />

        <SlideMinSeats />

        {/* <SelectChargerLevel /> */}

        <EVFilterType/>

        <EVFilterAvailableNow/>

        <div className="d-block d-lg-none">
          <button 
            className='apply-button-filter-modal'
            onClick={handleClick}  
            type="button"
          >
            APPLY FILTERS
          </button>
        </div>

      </div>

      <p className="selected-subText">
          <FormattedMessage
            id="evs.thirdParty"
            defaultMessage="We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability."
            description="Vehicles Disclaimer"
            values={{
              company: process.env.REACT_APP_FULL_COMPANY_NAME,
            }}
          />
      </p>
    </>
  );
};

export default MatchScoreOptions;

MatchScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
