import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IncentiveCatalog from './../../components/IncentiveCatalog/IncentiveCatalog';
import IncentiveScoreOptions from '../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions';
import IncentiveVehicleOptions from '../../client_customizations/components/IncentiveVehicleOptions/IncentiveVehicleOptions';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';
import { FormattedMessage } from 'react-intl';

import './Incentives.scss';

const Incentives = ({ electricVehicles, incentives, userLocation }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });



  const renderOptions = (
    <>
      <IncentiveScoreOptions userLocation={userLocation} />
      <br />
    </>
  );

  const renderVehicleOptions = electricVehicles ? (
    <IncentiveVehicleOptions electricVehicles={electricVehicles} />
  ) : null;

  const renderIncentives = incentives ? (
    <IncentiveCatalog 
      incentives={incentives} 
      category="all" 
      renderOptions={renderOptions}
      renderVehicleOptions={renderVehicleOptions}
    />
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <section className="container-fluid incentives-page" id="incentives">
        <div className='container'>
          <div className="row mb-3">
            <div className="col-sm-12 text-center justify-content-center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <h1 className="evHead">
                <FormattedMessage
                  id="incentives.title"
                  defaultMessage="Electric vehicle incentives"
                  description="Electric vehicle incentives"
                />
              </h1>
              <div className='yellow-bar'/>
            </div>
          </div>
          <div className="main-page-container" >
            <div className="col-xl-3 col-lg-4 d-none d-lg-block">
              {renderOptions}
              {renderVehicleOptions}
              <p className="disclaimer">
                <FormattedMessage
                  id="incentives.disclaimer"
                  defaultMessage="Incentive eligibility and amounts do not constitute tax or legal
              advice. Consult the incentive grantor and/or your tax or legal
              professional to determine eligibility, specific amount of
              incentives or rebates available."
                  description="Electric vehicle incentives"
                />
              </p>
            </div>
            <div
              className='cards-container'
            >
              {renderIncentives}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
