import React from "react";

const Input = props => {
  const { id, value, handler, label, description, isToggleSwitch } = props;

  const idAria = id + " help";

  return (
    <div
      className={isToggleSwitch ? "custom-control custom-switch" : "form-check"}
    >
      <input
        type="checkbox"
        id={id}
        className={isToggleSwitch ? "custom-control-input" : "form-check-input"}
        aria-labelledby={idAria}
        checked={value}
        onChange={e => handler(e)}
      />
      <label
        htmlFor={id}
        id={idAria}
        className={isToggleSwitch ? "custom-control-label" : "form-check-label"}
        style={{color: "#A0A0A0"}}
      >
        {label}
      </label>
     { description && <small id={idAria} className="form-text text-muted">
        {description}
      </small>}
    </div>
  );
};

export default Input;
