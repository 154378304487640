import React from 'react';
import PropTypes from 'prop-types';
import SelectedChargingStationCard from '../SelectedChargingStationCard/SelectedChargingStationCard';
import ChargingStationsMapLegend from '../ChargingStationsMapLegend/ChargingStationsMapLegend';

import './MapControlPanel.scss';

const MapControlPanel = ({
  chargingStations,
  children,
  selectedStation,
  toggleWithHistory,
}) => {
  return (
    <div className="MapControlPanel">
      <SelectedChargingStationCard
        toggleWithHistory={toggleWithHistory}
        station={selectedStation}
      />
      <div className="row map-controls">{children}</div>
      {(chargingStations || []).length > 0 && (
        <>
          <ChargingStationsMapLegend chargingStations={chargingStations} vertical={true}/>
        </>
      )}
    </div>
  );
};

MapControlPanel.propTypes = {
  chargingStations: PropTypes.array,
  children: PropTypes.node,
  selectedStation: PropTypes.object,
};

MapControlPanel.defaultProps = {
  chargingStations: [],
};

export default MapControlPanel;
