import React, { useContext } from 'react';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import Switch from 'react-switch';

import IconBEV from '../../assets/images/icons/Electric.svg';
import IconPHEV from '../../assets/images/icons/PHEV.svg';
import { useIntl, FormattedMessage } from 'react-intl';

const EVFilterControls = ({ vehicles }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const fuelTypeFilterData = userPrefs.get('vehicleFuelTypeFilters');

  const updateFuelTypeFilter = (checked, event, selectedKey) => {
    const newFilters = Object.keys(fuelTypeFilterData).reduce(function (
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !fuelTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      vehicleFuelTypeFilters: newFilters,
    });
  };

  const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
    (filterKey, i) => {
      let filterName = '';
      let filterIconSrc = '';
      switch (filterKey) {
        case 'bev':
          filterName = intl.formatMessage
            ? intl.formatMessage({
                id: 'vehicle.allElectric',
                defaultMessage: 'All Electric',
              })
            : 'All Electric';
          filterIconSrc = IconBEV;
          break;
        case 'phev':
          filterName = intl.formatMessage
            ? intl.formatMessage({
                id: 'vehicle.plugInHybrid',
                defaultMessage: 'Plug in Hybrid',
              })
            : 'Plug in Hybrid';
          filterIconSrc = IconPHEV;
          break;
        default:
      }

      return (
        <div key={i} className="filter-switch">
          <div className={i !== 0 ? 'filter-label' : 'filter-label first'}>
            <span className="badge-fuel-type">
              <img style={{ height: '16px', width: 'auto' }} src={filterIconSrc} alt="" />
            </span>
            <label for={filterKey}><span className="name">{filterName}</span></label>
            <div className="switch">
              <Switch
                class="custom-switch"
                trackColor={{ true: 'red', false: 'grey' }}
                id={filterKey}
                checked={fuelTypeFilterData[filterKey] ? true : false}
                onChange={updateFuelTypeFilter}
                onColor="#00657F"
                offColor="#ffffff"
                offHandleColor="#00657F"
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      );
    }
  );

  return (
    <div>
      <form>
        <div className="form-group">
          <span className="label-style" style={{fontWeight: 700, color: '#2A2A2A'}}>
            <FormattedMessage
              id="evfilter.fuel"
              defaultMessage="Fuel"
              description="Fuel"
            />
          </span>
          <div className="btn-block-container">{renderedFuelTypeFilters}</div>
        </div>
      </form>
    </div>
  );
};

export default EVFilterControls;
