/* global google */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import BaseGoogleMapReact from "../../../../components/LocationMap/BaseGoogleMapReact/BaseGoogleMapReact";
// import BaseGoogleMapReactRadius from "../../../../components/LocationMap/BaseGoogleMapReact/BaseGoogleMapReactRadius"
import MapControlPanel from "../../../../components/LocationMap/MapControlPanel/MapControlPanel";

import { FormatCarName } from "../../../../utils/Helpers/Format";
import isSuperChargingStation from "../../../../utils/predicates/isSuperChargingStation";
import GaTracker from "../../../../utils/GaTracker/GaTracker";

import useSelectedStation from "../../../../hooks/useSelectedStation";
import useChargingStations from "../../../../hooks/useChargingStations";
import Select from "../../../../components/shared/InputElements/SelectMap";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import useGeoJsonUrls from "../../../../hooks/useGeojsonUrls";
import setupGoogleMapsAutocomplete from "../../../../utils/setupGoogleMapsAutocomplete";
import useDeepCompareEffect from "../../../../hooks/useDeepCompareEffect";
import useMapBounds from "../../../../hooks/useMapBounds";

import PspsEventLegend from "../../PspsEventLegend/PspsEventLegend";
import MapLegend from "../../MapLegend/MapLegend";
import ToolTip from "../../../../components/shared/ToolTip/ToolTip";

import "./TravelRadiusMap.scss";
import { FormattedMessage, useIntl } from "react-intl";

const INITIAL_CENTER = {
  lat: 37.7916858,
  lng: -122.397855,
};

const TravelRadiusMap = ({
  chargingStationsFilterFn,
  electricVehicles,
  isVisible,
  canIgnoreLowPowerStations = true,
  pspsEvents = [],
  showStationsByPowerLevel,
  showStationsList,
  highPowerLegendTitle,
  highPowerLegendLabel,
  otherTypesLegendTitle,
  otherTypesLegendLabel,
  publicLegendTitle,
  publicLegendLabel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  showLegendCard,
  chargingListStyle,
  chargingListCardStyle,
  footerText,
  hideStations,
  onlyElectricRange,
  onlyTotalRange,
  zipcodePlaceholder,
  userLocation,
}) => {
  const [address, setAddress] = useState(
    "San Jose, CA, USA"
  );
  const [geocodeError, setGeocodeError] = useState();
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [isIgnoringLowPowerStations] = useState(canIgnoreLowPowerStations);
  // Tracking as state (instead of calculating from currentVehicle) because
  // we want to reset/update this when fetching the zipcode data and not when
  // we select a vehicle.
  // Stored as an object (instead of separate values) to reduce re-renders
  const [{ currentElectricRange, currentTotalRange }, setRangeInMi] = useState({
    currentElectricRange: null,
    currentTotalRange: null,
  });
  const mapRef = useRef(null);
  const geocoderRef = useRef(null);
  const electricCircleRef = useRef(null);
  const totalCircleRef = useRef(null);
  const autocompleteRef = useRef(null);

  const intl = useIntl();

  const [vehicleIdForTravelRadius, setVehicleIdForTravelRadius] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const { bounds, center, registerMapBoundsListeners, filterWithinBounds } =
    useMapBounds();

  const {
    chargingStations,
    fetchChargingStations,
  } = useChargingStations();

  const [selectedStation, selectStation, deselectStations] = useSelectedStation(chargingStations);

  const registerGeojsonUrlMap = useGeoJsonUrls(
    pspsEvents.flatMap((event) => event.file_urls)
  );

  useEffect(() => {
    if (electricVehicles && electricVehicles.length > 0) {
      const boltEv = electricVehicles.find(e => e.handle === 'Chevrolet_Bolt__BEV_2023');
      const chevroletVehicles = electricVehicles.filter(e => e.handle.toLowerCase() === 'chevrolet');
      
      let defaultVehicle = boltEv || chevroletVehicles[0] || electricVehicles[0];
  
      if (!vehicleIdForTravelRadius) {
        setVehicleIdForTravelRadius(defaultVehicle.electric_vehicle_id);
      }
    }
  }, [electricVehicles, vehicleIdForTravelRadius]);

  useDeepCompareEffect(() => {
    const { lat: latitude, lng: longitude } = center;
    fetchChargingStations({ latitude, longitude }, bounds);
  }, [center, bounds]);

  useEffect(() => {
    if (electricVehicles && electricVehicles.length) {
        electricVehicles.filter(
          (ev) =>
            (ev.fuel === "BEV" || ev.fuel === "PHEV")
        )
    }
  }, [electricVehicles]);

  const currentSelectedVehicle = (electricVehicles || []).find(
    (v) => v.electric_vehicle_id === selectedVehicle
  );

  const currentVehicle = (electricVehicles || []).find(
    (v) => v.electric_vehicle_id === Number(vehicleIdForTravelRadius)
  );

  const fetchAddress = ({ isTrackingEvent = true }) => {
    if (!address) return;

    setSelectedVehicle(vehicleIdForTravelRadius);
    setIsGeocoding(true);
    setGeocodeError(undefined);
    setRangeInMi({});

    if (isTrackingEvent) {
      GaTracker.trackEvent({
        category: "Maps",
        action: "Searched Travel Radius",
        label: FormatCarName(currentVehicle),
      });
    }

    geocoderRef.current.geocode(
      {
        address,
      },
      (results, status) => {
        setIsGeocoding(false);
        if (status === "OK") {
          renderMapWithCircles(results[0]);
        } else {
          setGeocodeError("There was an error geocoding this address");
        }
      }
    );
  };

  const onCompleteAddress = () => {
    setAddress(autocompleteRef.current.getPlace().formatted_address);
  };

  const handleGoogleMapsApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    /* eslint-disable no-undef */
    geocoderRef.current = new google.maps.Geocoder();

    if (!onlyElectricRange) {
      totalCircleRef.current = new google.maps.Circle({
        strokeColor: "#D1AF1E",
        strokeOpacity: 0.6,
        strokeWeight: 1,
        fillColor: "#D1AF1E",
        fillOpacity: 0.5,
        center: INITIAL_CENTER,
        radius: 0,
      });
    }

    electricCircleRef.current = new google.maps.Circle({
      strokeColor: "#00657F",
      strokeOpacity: 0.6,
      strokeWeight: 1,
      fillColor: "#00657F",
      fillOpacity: 0.5,
      center: INITIAL_CENTER,
      radius: 0,
    });
    /* eslint-enable no-undef */

    setupGoogleMapsAutocomplete(
      autocompleteRef,
      "travel-radius-map-address-input",
      onCompleteAddress
    );
    registerMapBoundsListeners(map);
    fetchAddress({ isTrackingEvent: false });

    registerGeojsonUrlMap(map);
  };

  const renderCircle = ({ circle, lat, lng, radiusInMeters, map }) => {
    circle.setCenter({
      lat,
      lng,
    });

    circle.setRadius(radiusInMeters);
    circle.setMap(map);
  };

  function calculateRadius(bounds) {
    function toRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    function haversineDistance(lat1, lon1, lat2, lon2) {
        var R = 6371e3; // earth R
        var dLat = toRadians(lat2 - lat1);
        var dLon = toRadians(lon2 - lon1);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; 
    }

    var lat1 = bounds.Gh.lo;
    var lon1 = bounds.Vh.lo;
    var lat2 = bounds.Gh.hi;
    var lon2 = bounds.Vh.hi;

    var distance = haversineDistance(lat1, lon1, lat2, lon2);

    var radius = distance / 2;

    return radius;
}

  const renderMapWithCircles = (result) => {
    const {
      geometry: {
        location: { lat, lng }, 
        viewport
      },
    } = result;
    const electricRadiusInMeters = currentVehicle
      ? currentVehicle.electric_range * 1609
      : 0;
    const totalRadiusInMeters = currentVehicle
      ? currentVehicle.total_range * 1609
      : 0;

    const { current: electricCircle } = electricCircleRef;
    const { current: totalCircle } = totalCircleRef;
    
    if (!onlyTotalRange) {
      renderCircle({
        circle: electricCircle,
        lat: lat(),
        lng: lng(),
        radiusInMeters: electricRadiusInMeters,
        map: mapRef.current,
      });
    }

    const isTotalRangeLarger = totalRadiusInMeters >= electricRadiusInMeters;

    if (!onlyElectricRange) {
      renderCircle({
        circle: totalCircle,
        lat: lat(),
        lng: lng(),
        radiusInMeters: totalRadiusInMeters,
        map: isTotalRangeLarger ? mapRef.current : null,
      });
    }
    renderCircle({
      circle: electricCircle,
      lat: lat(),
      lng: lng(),
      radiusInMeters: calculateRadius(viewport),
      map: isTotalRangeLarger ? mapRef.current : null,
    });

    mapRef.current.fitBounds(totalCircle.getBounds());
    setRangeInMi({
      currentElectricRange: currentVehicle.electric_range,
      currentTotalRange: currentVehicle.total_range,
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isSubmittingAllowed()) {
      fetchAddress({ isTrackingEvent: false });
    }
  };

  const visibleChargingStations = filterWithinBounds(chargingStations).filter(
    (station) =>
      isIgnoringLowPowerStations ? isSuperChargingStation(station) : true
  );
  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  const isVehicleSelectable = (electricVehicles || []).length > 1;

  const isSubmittingAllowed = () =>
    vehicleIdForTravelRadius && address && !isGeocoding;

  return !electricVehicles ? (
    <LoadingSpinner />
  ) : (
    <div className="row TravelRadiusMap">
      <div className="map-options" style={{ flexDirection: 'column'}}>
        <MapControlPanel
          selectedStation={selectedStation}
          chargingStations={hideStations ? [] : filteredChargingStations}
          showStationsByPowerLevel={showStationsByPowerLevel}
          highPowerLegendTitle={highPowerLegendTitle}
          highPowerLegendLabel={highPowerLegendLabel}
          otherTypesLegendTitle={otherTypesLegendTitle}
          otherTypesLegendLabel={otherTypesLegendLabel}
          publicLegendTitle={publicLegendTitle}
          publicLegendLabel={publicLegendLabel}
          publicIcon={publicIcon}
          highPowerIcon={highPowerIcon}
          defaultIcon={defaultIcon}
        >
          <div className="form-group zip-options">
            <label htmlFor="travel-radius-map-address-input">
              <FormattedMessage
                id="radiusMap.address"
                defaultMessage="ADDRESS"
                description="ADDRESS"
              />
            </label>
            <input
              id="travel-radius-map-address-input"
              type="text"
              className="form-control"
              onChange={(e) => setAddress(e.target.value)}
              onKeyPress={handleKeyPress}
              value={address}
              placeholder={zipcodePlaceholder || 'Address'}
              disabled={isGeocoding}
              style={{ height: '42px', backgroundImage: 'none' }}
            />
          </div>
          {isVehicleSelectable && (
            <div className="form-group power-options">
              <Select
                id={'select-vehicle-power-filter'}
                value={vehicleIdForTravelRadius}
                onChange={(e) => setVehicleIdForTravelRadius(e.target.value)}
                style={{ height: '42px' }}
                onKeyPress={handleKeyPress}
                disabled={isGeocoding}
                label={
                  intl.formatMessage
                    ? intl.formatMessage({
                        id: 'radiusMap.vehicle',
                        defaultMessage: 'VEHICLE',
                      })
                    : 'VEHICLE'
                }
              >
                {electricVehicles.map((electricVehicle) => {
                  return (
                    <option
                      value={electricVehicle.electric_vehicle_id}
                      key={electricVehicle.electric_vehicle_id}
                    >
                      {FormatCarName(electricVehicle)}
                    </option>
                  );
                })}
              </Select>
            </div>
          )}
          <div className="form-group" style={{ margin: 0 }}>
            <button
              className="btn btn-aps btn-detail"
              type="button"
              onClick={fetchAddress}
              disabled={!isSubmittingAllowed()}
              style={{ margin: 0, padding: '8px 24px' }}
            >
              <FormattedMessage
                id="viewTravelRadius"
                defaultMessage="View travel radius"
                description="View travel radius"
              />
            </button>
          </div>
        </MapControlPanel>
        {geocodeError && (
          <p className="text-danger">
            We could not find that address. Please revise your search and try
            again.
          </p>
        )}
      </div>
      <div className="col-lg-9">
        {userLocation && (
          <div className="map-container">
            <MapLegend>
              {(currentSelectedVehicle?.fuel_type === 'BEV' ||
                (!onlyTotalRange && currentElectricRange)) && (
                <div className="d-flex mb-1">
                  <span className="range-icon range-icon-electric" />
                  <span>
                    {currentElectricRange}{' '}
                    {'miles electric range, full battery*'}
                  </span>
                </div>
              )}
              <div className="d-flex mb-1">
                <span className="range-icon range-icon-electric" />
                <span>{address}</span>
              </div>
              {!onlyElectricRange &&
                currentTotalRange &&
                currentSelectedVehicle?.fuel_type !== 'BEV' && (
                  <div className="d-flex mb-1">
                    <span className="range-icon range-icon-total" />
                    <span>
                      {currentTotalRange}{' '}
                      {intl.formatMessage({
                        id: 'radiusSpecification',
                        defaultMessage: 'miles total range from full',
                      })}
                      <ToolTip
                        message={intl.formatMessage({
                          id: 'rangeMap',
                          defaultMessage:
                            'As advertised by the manufacturer. Actual range may differ, depending on environmental factors, driving behavior, vehicle maintenance, and battery age.',
                        })}
                        id="trade_in_label_tooltip"
                      />
                    </span>
                  </div>
                )}
              {pspsEvents.length > 0 && <PspsEventLegend />}
            </MapLegend>
            {isVisible && (
              <BaseGoogleMapReact
                onGoogleApiLoaded={handleGoogleMapsApiLoaded}
                onHoverOnStation={selectStation}
                onClickMarker={deselectStations}
                chargingStations={hideStations ? [] : filteredChargingStations}
                selectedStation={selectedStation}

                // showStationsByPowerLevel={showStationsByPowerLevel}
                // publicIcon={publicIcon}
                // highPowerIcon={highPowerIcon}
                // defaultIcon={defaultIcon}
                // publicIconSelected={publicIconSelected}
                // highPowerIconSelected={highPowerIconSelected}
                // defaultIconSelected={defaultIconSelected}
                // customizeMarkOnHover={customizeMarkOnHover}
              />
            )}
          </div>
        )}
      </div>

      <div>
        <p style={{ margin: '8px 0' }}>
          {intl.formatMessage({
            id: 'chargingMap.routeMapCopy',
            defaultMessage:
              'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model and automobile conditions.',
          })}
        </p>
      </div>
    </div>
  );
};

export default TravelRadiusMap;

TravelRadiusMap.propTypes = {
  canIgnoreLowPowerStations: PropTypes.bool,
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  isVisible: PropTypes.bool,
  pspsEvents: PropTypes.array,
  showStationsByPowerLevel: PropTypes.bool,
  showStationsList: PropTypes.bool,
  highPowerLegendTitle: PropTypes.string,
  highPowerLegendLabel: PropTypes.string,
  otherTypesLegendTitle: PropTypes.string,
  otherTypesLegendLabel: PropTypes.string,
  publicLegendTitle: PropTypes.string,
  publicLegendLabel: PropTypes.string,
  publicIcon: PropTypes.string,
  highPowerIcon: PropTypes.string,
  defaultIcon: PropTypes.string,
  searchBtnText: PropTypes.string,
  controlPanelTitle: PropTypes.string,
  customizeMarkOnHover: PropTypes.func,
  initialZoomLevel: PropTypes.number,
  showLegendCard: PropTypes.bool,
  searchBtnlabel: PropTypes.string,
  hideStations: PropTypes.bool,
};

TravelRadiusMap.defaultProps = {
  isVisible: true,
};
