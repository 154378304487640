import React from "react";
import PropTypes from "prop-types";

let VehicleImage = ({ image, size, isEvPage }) => {
  if (!image) return null;
  const src = size === "full" ? image.url_full : image.url_thumbnail;
  let alt = image["alt"];

  return <img src={src} alt={alt} className={`img-fluid ${isEvPage && 'ev-page'}`} />;
};

export default VehicleImage;

VehicleImage.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  size: PropTypes.string
};
