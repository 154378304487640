import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import DISCOVER_ELECTRIC_VEHICLES from "../../../client_customizations/assets/images/icons/carIcon.svg";
import MAXIMIZE_INCENTIVES from "../../../client_customizations/assets/images/icons/dolarIcon.svg";
import LOCATE_CHARGING_STATIONS from "../../../client_customizations/assets/images/icons/fuelIcon.svg";

import { Link } from "react-router-dom";
import SmoothScroll from "../../../utils/Helpers/SmoothScroll";
import {FormattedMessage} from 'react-intl';

const LinkCard = ({ type }) => {
  let src, link, scrollTo, title;

  switch (type) {
    case "DISCOVER_ELECTRIC_VEHICLES":
      src = DISCOVER_ELECTRIC_VEHICLES;
      link = "/vehicles";
      title = (
        <>
          <span className="d-none d-lg-block">
            <FormattedMessage 
              id="homepage.linkCard.browseElectricVehicles"
              defaultMessage="DISCOVER ELECTRIC VEHICLES"
              description="HomePage Linkcard Browse Electric Vehicles"
          
            />
          </span>

          <span className="d-block d-lg-none">
            <FormattedMessage 
              id="homepage.linkCard.browseElectricVehicles.short"
              defaultMessage="DISCOVER ELECTRIC VEHICLES"
              description="HomePage Linkcard Browse Electric Vehicles"
          
            />
          </span>
        </>
      );
      break;
    case "MAXIMIZE_INCENTIVES":
      src = MAXIMIZE_INCENTIVES;
      link = "/incentives";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.discoverIncentives"
            defaultMessage="MAXIMIZE INCENTIVES"
            description="HomePage Linkcard Discover Incentives"
 
          />
        </span>
      );
      break;
    case "LOCATE_CHARGING_STATIONS":
      src = LOCATE_CHARGING_STATIONS;
      link = "/charging-stations";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.locateChargingStations"
            defaultMessage="LOCATE CHARGING STATIONS"
            description="HomePage Linkcard Locate Charging Stations"

          />
        </span>
      );
      break;
    // case "SHOP_HOME_CHARGERS":
    //   src = SHOP_HOME_CHARGERS;
    //   link = "/home-chargers";
    //   title = (
    //     <span>
    //       <FormattedMessage 
    //         id="homepage.linkCard.homeChargers"
    //         defaultMessage="SHOP {lineBreak} HOME CHARGERS"
    //         description="HomePage Linkcard Home Chargers"
    //         values= {{
    //           lineBreak: <br />
    //         }}
    //       />
    //     </span>
    //   );
    //   break;
    default:
      return null;
  }

  const iconMask = `url(${src})`;

  return scrollTo ? (
    <div className="link-card">
      <div className="evc-card" onClick={e => SmoothScroll(scrollTo)}>
        <div>
          <img src={src} alt={title}/>
          <p className="h6">{title}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="link-card">
      <Link to={link} className="evc-card">
        <div>
        <div className="icon" style={{maskImage: iconMask, WebkitMaskImage: iconMask}}/>
          <p className="h6">{title}</p>
        </div>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};
