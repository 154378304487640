import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import EVIncentives from "../../components/EVIncentives/EVIncentives";
import EVJumbotron from "../../client_customizations/components/EVJumbotron/EVJumbotron";
import EVReviews from "../../components/EVReviews/EVReviews";
import ChargingMap from "../../components/LocationMap/ChargingMap";
import CostOfOwnership from "../../components/CostOfOwnership/CostOfOwnership";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import isPHEV from "../../functions/vehicle/isPHEV";
import { FormattedMessage } from 'react-intl';
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import getPaymentDetails from "../../functions/vehicle/getPaymentDetails";
import { FormatCarName } from "../../utils/Helpers/Format";

const UsedEV = ({
  match,
  electricVehicles,
  userLocation,
  ip,
  uuid,
  zipcode
}) => {
  // const userPrefs = useContext(UserPrefsContext);
  const [usedEv, setUsedEv] = useState(null)
  const [usedEvs, setUsedEvs] = useState(null)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const userPrefs = useContext(UserPrefsContext);

  useEffect(() => {
    const setElectricVehicles = () => {
      if (electricVehicles) {
        const evId = match ? match.params['evId'] : 0;
        const filteredCar = electricVehicles.find((ev) => ev.handle === evId);
    
        setUsedEv(filteredCar)
      }
    }

    setElectricVehicles()
  }, [electricVehicles, match]);

  useEffect(() => {
    if(usedEv) {
      const newPaymentDetails = getPaymentDetails(
        usedEv,
        userPrefs.get('monthsOfOwnership'),
        userPrefs.get('interestRateAsBasisPoints'),
        userPrefs.get('milesDrivenAnnually'),
        userPrefs.get('salesTax'),
        userPrefs.get('downPayment')
      );

      setPaymentDetails(newPaymentDetails)
      document.title = usedEv ? `${FormatCarName(usedEv)}` : '';

      const allUsedEvs = electricVehicles.filter(ev => {
        return ev.make === usedEv.make && ev.model === usedEv.model && ev.trim === usedEv.trim
      })

      setUsedEvs(allUsedEvs)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usedEv])

  const ignoreSuperchargerStations = usedEv && isPHEV(usedEv);

  const renderEV = (usedEv && paymentDetails) ? (
    <>
      <EVJumbotron
        car={usedEv}
        incentives={usedEv.incentives}
        cars={usedEvs}
        setUsedEv={setUsedEv}
        paymentDetails={paymentDetails}
      />
      <CostOfOwnership cars={[usedEv, usedEv.equivalent_gas_vehicle]} usedEv={true} userLocation={userLocation}/>
      <EVIncentives incentives={usedEv.incentives} car={usedEv} usedEv={true} />
      
      <div className="container-fluid d-none d-lg-none" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="container pb-4">
          <p className="h1 pt-4 mb-3 text-center">
            <FormattedMessage
              id="chargingStations"
              defaultMessage="Charging Stations"
              description="Charging Stations"
            />
          </p>
          <div style={{
            borderRadius: "3px",
            border: "1px solid #dee2e6",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,.15)",
            padding: "20px",
            backgroundColor: "#fff"
          }}>
            <ChargingMap
              userLocation={userLocation}
              zipcode={zipcode}
              ignoreSuperchargerStations={ignoreSuperchargerStations}
            />
          </div>
        </div>
      </div>
      <EVReviews reviews={usedEv.video_reviews} />
    </>
  ) : (
    <div className="container text-center">
      <LoadingSpinner />
    </div>
  );

  return (
    <>
      {renderEV}
    </>
  );
};

export default UsedEV;

UsedEV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
