import React from "react";
import PropTypes from "prop-types";
import "./UsedVehiclePanels.scss";
import { TabContent, TabPane } from "reactstrap";
import { FormatAsDollars } from "../../../utils/Helpers/Format";
import sortIncentives from "../../../functions/incentives/Sort/sortIncentives"
import SelectVehicleYearFilter from "../../InputComponents/SelectVehicleYearFilter/SelectVehicleYearFilter"
import { FormattedMessage } from 'react-intl';

const UsedVehiclePanels = ({ cars, car, setUsedEv }) => {

  if (!cars) return null;

  const usedIncentives = car.incentives.filter(incentive => incentive.applicable_to_used_vehicles)

  const totalSavings = sortIncentives(usedIncentives).total("eligible-rebates");
  
  return (
    <>
      <div className="PricePanels">
        <TabContent activeTab={"1"}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem">
                  <SelectVehicleYearFilter car={car} vehicles={cars} setUsedEv={setUsedEv}/> 
                </div>
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle text-center">
                      <FormattedMessage
                        id="vehicle.afterIncentives"
                        defaultMessage="After Incentives"
                        description="Price Panel After Incentives"
                      />
                    </p>
                    <p className="h1">
                      {`${FormatAsDollars(car.lowerBoundMsrp - totalSavings)} - ${FormatAsDollars(car.upperBoundMsrp - totalSavings)}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default UsedVehiclePanels;

UsedVehiclePanels.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};
