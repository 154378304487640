import React from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import {
  ALL_STATIONS_TAB_ID,
  MAP_TABS,
  ROUTE_MAP_TAB_ID,
  RANGE_MAP_TAB_ID
} from '../../../constants/mapTabs';
import ChargingMap from '../LocationMap/ChargingMap/ChargingMap';
import { useIntl } from 'react-intl';

import './TabbedMaps.scss';
import TravelRouteMap from '../../../components/LocationMap/TravelRouteMap/TravelRouteMap';
import TravelRadiusMap from '../LocationMap/TravelRadiusMap/TravelRadiusMap';

const activeClass = (tabNumber, activeTab) =>
  activeTab === tabNumber ? 'active' : '';

const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  activeTabId,
  toggle,
  userLocation,
  zipcode,
  pspsEvents = [],
}) => {
  const intl = useIntl();

  return (
    <div className="TabbedMaps">
      <div className="form-group d-md-none">
        <select
          value={activeTabId}
          onChange={(e) => toggle(e.target.value)}
          className="form-control"
        >
          {MAP_TABS.map((tab) => (
            <option key={tab.name} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="container tabbed-container">
        <div className="tabbed-maps-panel">
          <Nav tabs className="d-none d-md-flex">
            {MAP_TABS.map((tab) => (
              <NavItem key={tab.name}>
                <NavLink
                  className={activeClass(tab.id, activeTabId)}
                  onClick={() => {
                    toggle(tab.id);
                  }}
                >
                  {intl.formatMessage
                    ? intl.formatMessage({
                        id: tab.id,
                        defaultMessage: '',
                      })
                    : tab.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTabId}>
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
              <TabPane tabId={ALL_STATIONS_TAB_ID}>
                <ChargingMap
                  userLocation={userLocation}
                  isVisible={activeTabId === ALL_STATIONS_TAB_ID}
                  zipcode={zipcode}
                  toggleWithHistory={toggle}
                />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED && (
              <TabPane tabId={ROUTE_MAP_TAB_ID}>
                <TravelRouteMap
                  onlyTotalRange
                  chargingStationsFilterFn={chargingStationsFilterFn}
                  electricVehicles={electricVehicles}
                  isVisible={activeTabId === ROUTE_MAP_TAB_ID}
                  canIgnoreLowPowerStations={false}
                  zipcodePlaceholder="Choose a location"
                  pspsEvents={pspsEvents}
                  showStationsByPowerLevel
                  showLegendCard={false}
                  hideStations
                  userLocation={userLocation}
                  btnStyles={{ width: '100%' }}
                  footerText=""
                />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_RANGE_MAP_ENABLED && (
              <TabPane tabId={RANGE_MAP_TAB_ID}>
                <TravelRadiusMap
                  onlyTotalRange
                  chargingStationsFilterFn={chargingStationsFilterFn}
                  electricVehicles={electricVehicles}
                  isVisible={activeTabId === RANGE_MAP_TAB_ID}
                  canIgnoreLowPowerStations={false}
                  zipcodePlaceholder="Choose a location"
                  pspsEvents={pspsEvents}
                  showStationsByPowerLevel
                  showLegendCard={false}
                  hideStations
                  userLocation={userLocation}
                  btnStyles={{ width: "100%" }}
                  footerText=""
                />
              </TabPane>
            )}

          </TabContent>
        </div>
      </div>
    </div>
  );
};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;
