import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EVCard from '../../../components/EVCard/EVCard';
import { FormattedMessage } from 'react-intl';
import './VehicleCarousel.scss';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import arrow from '../../assets/images/icons/arrow_forward.svg'

const VehicleCarousel = ({ electricVehicles, userLocation, language }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [ width, setWidth ] = useState(null)

  useEffect(() => {
    if(language === 'EN') setWidth(886)
    if(language === 'ES') setWidth(1100)
    if(language === 'VI') setWidth(840)
  },[language])
  

  if (!electricVehicles)
    return (
      <section className="container-fluid grey">
        <LoadingSpinner />
      </section>
    );

  if (!electricVehicles || electricVehicles.length <= 3) return null;
  if (!userLocation) return null;


  const cheapestVehicles = electricVehicles
    .sort((a, b) => a.msrp - b.msrp)
    .slice(0, 8); 
    
  const MNGoldDealers = new Set([
    'Mitsubishi',
    'Nissan',
    'Chevrolet',
    'Ford',
    'Volkswagen',
    'Toyota',
    'Tesla',
    'Jaguar',
    'Land Rover',
    'Volvo',
  ]);

  const COGoldDealers = new Set([
    'Audi',
    'Nissan',
    'Ford',
    'Volkswagen',
    'Hyundai',
    'Kia',
    'Tesla',
    'Jaguar',
  ]);

  const stateGoldDealers = {
    Minnesota: MNGoldDealers,
    Colorado: COGoldDealers,
  };

  if (stateGoldDealers[userLocation.region]) {
    electricVehicles = electricVehicles.filter((ev) => {
      return stateGoldDealers[userLocation.region].has(ev.make);
    });
  }

  const filteredEVs = cheapestVehicles;

  const nextVehicle = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex + 4 < filteredEVs.length) { 
        return prevIndex + 1;
      }
      return prevIndex;
    });
  };

  const prevVehicle = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex > 0) { 
        return prevIndex - 1;
      }
      return prevIndex;
    });
  };


  return (
    <section className="dicover-section">
      <div className="dicover-container">
        <div className="text-container">
          <h2 className="title-head">
            <FormattedMessage
              id="homepage.vehicleCarousel.title"
              defaultMessage="There are more electric vehicles than ever."
              description="Vehicle Carousel Title"
            />
          </h2>
          <span className="title-head-discover">
              {' '}
              <FormattedMessage
                id="homepage.vehicleCarousel.title.discover"
                defaultMessage="Discover yours."
                description="Vehicle Carousel Title Discover"
              />
          </span>
        </div>
        <h3 className="title-sub" style={{maxWidth: width}}>
          <FormattedMessage
            id="homepage.vehicleCarousel.subTitle"
            defaultMessage="Compare the cost of your selection to a similar gas vehicle. An electric car can save you money {lineBreak}because it can be less expensive to own and maintain than a 100% gasoline-powered car."
            description="Vehicle Carousel Title"
            values={{
              lineBreak: <br />,
            }}
          />
        </h3>
        <Link to="/vehicles" className="btn btn-aps" role="button">
          <FormattedMessage
            id="homepage.vehicleCarousel.compareVehiclesButton"
            defaultMessage="FIND YOUR ELECTRIC VEHICLE"
            description="FIND YOUR ELECTRIC VEHICLE"
          />
        </Link>
      </div>

      <div className="carousel-container">
        <button onClick={prevVehicle} className={`arrow-container left ${(currentIndex > 0) && 'enabled'}`}>
          <img src={arrow} alt='arrow left' />
        </button>
        <div className="car-list">
          {filteredEVs
            .slice(currentIndex, currentIndex + 4)
            .map((ev, i) => (
              <EVCard
                key={i}
                ev={ev}
                hasEvDetails={true}
                hasIncentivesAndMatchScore={true}
              />
            ))}
        </div>
        <button onClick={nextVehicle} className={`arrow-container ${(currentIndex + 4 < filteredEVs.length) && 'enabled'}`}>
          <img src={arrow} alt='arrow right' />
        </button>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
};
